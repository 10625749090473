import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    logoWrapper: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      zIndex: 2,

      [theme.breakpoints.up("lg")]: {
        justifyContent: "start",
      },
    },
    logo: {
      maxWidth: "90px",
      marginTop: "20px",

      [theme.breakpoints.up("xl")]: {
        maxWidth: "120px",
      },
    },
  });
});
