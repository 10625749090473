import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    section: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
      padding: "48px 20px 0",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "150px 0 0",
      },
    },
    heading: {
      textAlign: "left",
      padding: "24px 0",

      [theme.breakpoints.up("lg")]: {
        textAlign: "center",
        padding: "24px 64px",
      },
    },
    formWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    formWrapperInner: {
      width: "700px",
      maxWidth: "95vw",
    },
  });
});
