import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useStyles } from "./soundEmbed.styles";

interface IPropBlok extends IBlok {
    heading: string;
    embed: IAsset;
}

interface IProps {
    blok: IPropBlok;
}

const SoundEmbed = ({ blok }: IProps) => {
    const styles = useStyles();

    return (
        <Container className={ styles.container }>
            <Typography variant="h4" component="h4" className={ styles.heading }>{ blok.heading }</Typography>
            <audio controls src={blok.embed.filename}></audio>
        </Container>
    );
}

export default SoundEmbed;