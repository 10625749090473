import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PinterestIcon from "@material-ui/icons/Pinterest";
import { useStyles } from "./socialMediaLinks.styles";

export interface ISocialMediaLinks {
  blok: {
    twitter: ILink;
    facebook: ILink;
    pinterest: ILink;
    linkedin: ILink;
  };
}

const SocialMediaLinks = ({ blok }: ISocialMediaLinks) => {
  const classes = useStyles();
  const { twitter, facebook, pinterest, linkedin } = blok;

  return (
    <div className={classes.linkContainer}>
      <a href={facebook.url} target="_blank" className={classes.link}>
        <FacebookIcon />
      </a>
      <a href={twitter.url} target="_blank" className={classes.link}>
        <TwitterIcon />
      </a>
      <a href={linkedin.url} target="_blank" className={classes.link}>
        <LinkedInIcon />
      </a>
      <a href={pinterest.url} target="_blank" className={classes.link}>
        <PinterestIcon />
      </a>
    </div>
  );
};

export default SocialMediaLinks;
