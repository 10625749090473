import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Container, Typography } from "@material-ui/core";
import { useStyles } from "./hero.styles";
import CtaButton from "../ctaButton";
import Logo from "../logo";

interface IPropBlok extends IBlok {
  logo: IAsset;
  preHeading: string;
  heading: string;
  subheading: string;
  ctaButton: [ICtaButton];
  ctaButtonText: string;
  ctaDisclaimerText: string;
  ctaAnchorLink?: string;
  background_image: IAsset;
  component: string;
}

interface IProps {
  blok: IPropBlok;
}

const Hero = ({ blok }: IProps) => {
  const fullResolutionHeroImage = blok.background_image.filename;
  const lazyHeroImage = `${fullResolutionHeroImage}/m/250x0`;
  const [heroImage, setHeroImage] = useState(lazyHeroImage);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setHeroImage(fullResolutionHeroImage);
    }, 150);
  }, []);

  return (
    <Box
      id={blok.anchorTag}
      className={classes.container}
      style={{
        backgroundImage: `url(${heroImage})`,
        margin: 0,
      }}
    >
      <Container>
        {blok?.logo?.filename && <Logo filename={blok?.logo?.filename} />}
        <Box className={classes.contentWrapper}>
          <Typography
            variant="h4"
            component="h5"
            className={classes.preHeading}
          >
            {blok?.preHeading}
          </Typography>
          <Typography variant="h3" component="h2" className={classes.heading}>
            {blok?.heading}
          </Typography>
          <Typography
            variant="h4"
            component="h3"
            className={classes.subheading}
          >
            {blok?.subheading}
          </Typography>
          <div
            style={{
              paddingBottom: 100,
            }}
          >
            {blok?.ctaButton?.length > 0 && (
              <CtaButton ctaButton={blok.ctaButton} />
            )}
            {blok.ctaDisclaimerText && (
              <Typography variant="body1" className={classes.disclaimerText}>
                {blok.ctaDisclaimerText}
              </Typography>
            )}
          </div>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
