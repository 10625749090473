import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Bullet from "../bullet";
import { useStyles } from "./bulletTextSection.styles";
import Paragraphs from "../paragraphs";

interface IBullet extends IBlok {
  text: IContent;
}

interface IPropsBlok extends IBlok {
  heading: string;
  text?: IContent;
  bullets: IBullet[];
}

interface IProps {
  blok: IPropsBlok;
}

const BulletTextSection = ({ blok }: IProps) => {
  const classes = useStyles();
  return (
    <Container id={blok.anchorTag} className={classes.container}>
      <div className={classes.contentWrapper}>
        <Typography className={classes.heading} variant="h3" component="h3">
          {blok.heading}
        </Typography>
        {blok.text && <Paragraphs paragraphs={blok.text} />}
        {blok.bullets.map((bullet) => (
          <Bullet text={bullet.text} key={bullet._uid} />
        ))}
      </div>
    </Container>
  );
};

export default BulletTextSection;
