import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./liveStreamHero.styles";
import CtaButton from "../ctaButton";

interface IProps {
  blok: {
    heading: string;
    subheading: string;
    dateText: string;
    ctaButton: [ICtaButton];
  };
}

const LiveStreamHero = ({ blok }: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.notice}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon} />
        </div>
        <Typography className={styles.noticeText} variant="body2" component="p">
          Streaming live online in hd
        </Typography>
      </div>
      <Typography className={styles.title} variant="h1" component="h1">
        {blok.heading}
      </Typography>
      <Typography className={styles.subtitle} variant="h3" component="h3">
        {blok.subheading}
      </Typography>
      <Typography className={styles.text} variant="h4" component="h4">
        {blok.dateText}
      </Typography>
      <div className={styles.btnContainer}>
        {blok?.ctaButton?.length > 0 && (
          <CtaButton ctaButton={blok.ctaButton} />
        )}
      </div>
    </div>
  );
};

export default LiveStreamHero;
