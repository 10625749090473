import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./heroWithVideo.styles";
import CtaButton from "../ctaButton";
import TwoColumnSection from "../twoColumnSection";
import Vimeo from "../vimeo";

interface IProps {
  blok: {
    heading: string;
    subheading: string;
    dateText: string;
    ctaButton: [ICtaButton];
    videoUrl: string;
  };
}

const HeroWithVideo = ({ blok }: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <TwoColumnSection
        left={
          <>
            <Typography className={styles.title} variant="h1" component="h1">
              {blok.heading}
            </Typography>
            <Typography className={styles.subtitle} variant="h3" component="h3">
              {blok.subheading}
            </Typography>
            <Typography className={styles.text} variant="h4" component="h4">
              {blok.dateText}
            </Typography>
            <div className={styles.btnContainer}>
              {blok?.ctaButton?.length > 0 && (
                <CtaButton ctaButton={blok.ctaButton} />
              )}
            </div>
          </>
        }
        right={<Vimeo videoUrl={blok.videoUrl} />}
      />
    </div>
  );
};

export default HeroWithVideo;
