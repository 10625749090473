import React, { useEffect, useState } from 'react'
import { useStyles } from './navHeader.styles';
import "./navHeader.animation.styles.css";
import { getDesktopNavData } from './data';
import CartIcon from './assets/icon-cart.svg'
import MenuIcon from './assets/icon-menu.svg'
import Container from "@material-ui/core/Container";
import headerBgImg from './assets/header-bg-web.jpg';
import logoImg from './assets/logo2.png';

import classNames from 'classnames';

interface IProps {
  blok: {
    logo: IAsset;
    backgroundImage: IAsset;
  };
}

export interface ILinkItem {
    name: string,
    path: string
}

const IS_STICKY_HEADER = false;

const NavHeader = ({ blok }: IProps) => {
    const classes = useStyles();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [menuAnimationClass, setMenuAnimationClass] = useState('');

    const target = "_blank";

    const toggleMenu = () => {
        if(isMenuOpen)
            setMenuAnimationClass('slide-up-header')
        else
            setMenuAnimationClass('slide-down-header')

        setIsMenuOpen(!isMenuOpen)
    }

    useEffect(() => {
        const handleScrollToElement = () => {
            if (IS_STICKY_HEADER) {
                const status = window.scrollY > 10
                setScrolled(status)
            }
        }
        window.addEventListener('scroll', handleScrollToElement);
        return () => window.addEventListener('scroll', handleScrollToElement);
    }, [])

    return (
        <header style={{backgroundImage: `url(${blok.backgroundImage?.filename || headerBgImg})`}}
                className={classNames(classes.header, {'scrolled': !!scrolled, 'fixed': IS_STICKY_HEADER})}>
            <Container className={classes.container}>
                <div className={classes.logoContainer}>
                    <span className={classes.logoHelper}></span>
                    <a href="https://www.holdenqigong.com/">
                        <img className={classes.logo} src={blok.logo.filename || logoImg} alt="logo" />
                    </a>
                </div>
                <div className={classNames(classes.etTopNavigation, {'scrolled': !!scrolled})}>
                    <nav className={classes.topMenuNav}>
                        <ul className={classes.topMenu}>
                            {getDesktopNavData(classes).map(item => (
                                <li key={item.name}>
                                    <a href={item.link} target={item?.target || target} rel={!!item?.target || !!target ? 'noopener' : undefined}>{item.name} {item?.icon}</a>
                                    {!!item?.menu && (
                                        <ul key="menu-list-top">
                                            {item.menu?.map((menuItem) => (
                                                <li key={menuItem.name} className={classes.menuItem}>
                                                    <a href={menuItem.link} target={menuItem?.target || target} rel={!!menuItem?.target || !!target ? 'noopener' : undefined}>{menuItem.name} {menuItem?.icon}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <a href="https://www.holdenqigong.com/cart/" target={target} aria-label="cart" className={classes.etCartInfo}>
                        <CartIcon className={classes.cartIcon} />
                    </a>

                    <div className={classes.etMobileNavMenu}>
                        <div className={classes.mobileNav}>
                            <MenuIcon onClick={toggleMenu} role="button" className={classes.menuIcon} />
                            <ul className={
                                classNames(
                                    classes.etMobileMenu,
                                    menuAnimationClass,
                                    {
                                        [classes.mobMenuOpen]: isMenuOpen,
                                    })}>
                                {getDesktopNavData(classes).map(item => (
                                    <li key={item.name}>
                                        <a href={item.link} target={item?.target || target} rel={!!item?.target || !!target ? 'noopener' : undefined}>{item.name}</a>
                                        {!!item?.menu && (
                                            <ul key="menu-list">
                                                {item.menu?.map((menuItem) => (
                                                    <li key={menuItem.name} className={classes.menuItem}>
                                                        <a href={menuItem.link} target={menuItem?.target || target} rel={!!menuItem?.target || !!target ? 'noopener' : undefined}>{menuItem.name} {menuItem?.icon}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </header>
    )
}

export default NavHeader