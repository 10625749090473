import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    section: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
      padding: "24px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0",
      },
    },
    column: {
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        width: "calc(50% - 45px)",
      },

      "& p:last-of-type": {
        margin: 0,
      },
    },
  });
});
