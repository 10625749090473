import React from "react";
import Typography from "@material-ui/core/Typography";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import Img from "gatsby-image";
import Paragraphs from "../paragraphs";
import TwoColumnSection from "../twoColumnSection";
import { IContent } from "../paragraphs";

interface IPropsBlok extends IBlok {
  image: IAsset;
  heading: string;
  signatureImage: IAsset;
  content: IContent;
}

interface IProps {
  blok: IPropsBlok;
}

const About = ({ blok }: IProps) => {
  const fluidProps = getFluidGatsbyImage(blok.image.filename, {
    maxWidth: 900,
  });

  return (
    <TwoColumnSection
      id={blok.anchorTag}
      toggleStackOrder
      left={
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Img alt={blok.image.alt} fluid={fluidProps} />
        </div>
      }
      right={
        <div
          style={{
            maxWidth: 900,
            margin: "0 auto",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            style={{
              padding: "0 0 24px",
              textAlign: "left",
            }}
          >
            {blok?.heading}
          </Typography>
          <Paragraphs paragraphs={blok.content} />
          <img
            style={{
              width: "100%",
              maxWidth: 350,
            }}
            src={blok.signatureImage.filename}
          />
        </div>
      }
    />
  );
};

export default About;
