import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    heading: {
      marginBottom: "50px",
      margin: "0 auto",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "80px",
      marginBottom: "80px",
    },
  });
});
