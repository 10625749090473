import React from "react";
import useEmbed from "../../hooks/useEmbed";

interface IProps {
  blok: {
    embed: string;
    cssId: string;
    cssStyles: string;
  };
}

const CustomEmbed = ({ blok }: IProps) => {
  // extracts the div from the embed code and render it in place.
  const embed = blok.embed;
  let html = parseEmbed(embed, blok.cssId);

  if (blok.cssStyles) {
    html += "<style>" + blok.cssStyles + "</style>";
  }

  if (blok.cssId) {
    return (
      <div id={blok.cssId}
           dangerouslySetInnerHTML={{ __html: html }} />
    );
  } else {
    return ( <div dangerouslySetInnerHTML={{ __html: html }} /> );
  }

  function parseEmbed (embed: string, cssId: string) {
    let html = "";
    const frag = document.createRange().createContextualFragment(embed);
    const childNodes = frag.childNodes;

    /* For all nodes in the embed HTML:
     *  If you find a div without a script inside, return HTML to be placed inline
     *  If you find a script, load it at the bottom of the page.
     *  If you find a div, with a script inside, put it all at the bottom
     *     and move it into place (important for ticket tailor)  */
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];
      if (childNode instanceof HTMLDivElement) {
        if (childNode.querySelector('script')) {
          useEmbed(childNode, cssId);
        } else {
          html += childNode.outerHTML;
        }
      } else if (childNode instanceof HTMLScriptElement) {
        useEmbed(childNode);
      }
    }

    return html;
  }
};

export default CustomEmbed;
