import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    footer: {
      color: "#fff",
      padding: "22px 0",
      backgroundColor: theme.palette.primary.main,
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },

    copy: {
      color: "#fff",
      margin: 0,
    },

    footerLink: {
      fontFamily: "Museo-Sans-Cyrl-300 !important",
      fontWeight: "normal",
      textDecoration: "none",
      color: "#fff !important",
      // margin: "0 20px"
    }

  });
});
