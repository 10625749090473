import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      backgroundColor: "#fafafa",
      padding: "24px 20px 0",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px 0",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0 0",
      },
    },
  });
});
