import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    priceText: {
      color: "#fff",
      padding: "0 0 26px",
    },
    pretendPriceText: {
      color: theme.palette.secondary.main,
    },
    contentWrapper: {
      textAlign: "center",
      width: "100%",
      margin: "0 auto",
      maxWidth: "900px",
    },
    buttonWrapper: {
      margin: "64px 0 40px",
    },
  });
});
