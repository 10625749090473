import React from "react";
import Typography from "@material-ui/core/Typography";
import OneColumnSection from "../oneColumnSection";
import CtaButton from "../ctaButton";
import { useStyles } from "./ctaSectionBreakBanner.styles";

interface IPropsBlok extends IBlok {
  heading: string;
  subheading: string;
  ctaButton: Array<ICtaButton>;
  disclaimerText?: string;
}

interface IProps {
  blok: IPropsBlok;
}

const CTASectionBreakBanner = ({ blok }: IProps) => {
  const classes = useStyles();
  return (
    <OneColumnSection
      id={blok.anchorTag}
      backgroundStyle="dark"
      widthStyle="text"
    >
      <div className={classes.contentWrapper}>
        <Typography className={classes.heading} variant="h3" component="h3">
          {blok.heading}
        </Typography>
        <Typography className={classes.subheading} variant="h4" component="h4">
          {blok.subheading}
        </Typography>
      </div>
      {blok.ctaButton?.length > 0 && <CtaButton ctaButton={blok.ctaButton} />}
      {blok.disclaimerText && (
        <Typography variant="body1" className={classes.disclaimerText}>
          {blok.disclaimerText}
        </Typography>
      )}
    </OneColumnSection>
  );
};

export default CTASectionBreakBanner;
