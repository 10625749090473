import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        card: {
            maxWidth: "32%",
        },
        cardImage: {
            width: "100%",
        },
        moreButton: {
            backgroundColor: "#FFF",
            color: theme.palette.secondary.dark,
            border: `1px ${theme.palette.secondary.dark} solid`,
        },
        buttonContainer: {
            display: "flex",
            gap: "16px"
        },
    });
});
