import React from "react";
import CardGrid from "../cardGrid";
import InfoCard from "../infoCardWidget";

interface IInfoCard {
  icon?: IAsset;
  heading: string;
  content: IContent;
}

interface IPropsBlok extends IBlok {
  heading: string;
  infoCards: IInfoCard[];
}

interface IProps {
  blok: IPropsBlok;
}

const InfoGrid = ({ blok }: IProps) => {
  return (
    <CardGrid id={blok.anchorTag} heading={blok.heading}>
      {blok.infoCards.map(({ icon, heading, content }) => (
        <InfoCard icon={icon} heading={heading} content={content} />
      ))}
    </CardGrid>
  );
};

export default InfoGrid;
