import React from 'react';
import CardGrid from '../cardGrid';
import ProductWidget from "../productWidget";

interface IProduct {
    image: IAsset;
    heading: string;
    description: IContent;
    ctaButton: ICtaButton[];
    moreButton: ICtaButton[];
}

interface IPropsBlok extends IBlok {
    heading: string;
    subHeading: string;
    products: [IProduct, IProduct, IProduct];
}

interface IProps {
    blok: IPropsBlok;
}

const ProductGrid = ({ blok }: IProps) => {
    return (
        <CardGrid id={blok.anchorTag} heading={blok.heading} subheading={blok.subHeading}>
            {blok.products.map(({ image, heading, description, ctaButton, moreButton }: IProduct) => (
                <ProductWidget
                    image={image}
                    heading={heading}
                    description={description}
                    ctaButton={ctaButton}
                    moreButton={moreButton}
                />
            ))}
        </CardGrid>
    );
};
export default ProductGrid;