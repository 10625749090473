import React from "react";
import { useStyles } from "./ctaButton.styles";
import CtaLink from "../ctaLink";

interface IProps {
  variant?: "main" | "alt";
  ctaButton: Array<ICtaButton>;
  className?: string;
}

const CtaButton = ({ variant, ctaButton, className }: IProps) => {
  const classes = useStyles();
  const baseClass = variant === "alt" ? classes.altButton : classes.button;

  return (
    <CtaLink
      className={className ? `${baseClass} ${className}` : baseClass}
      link={ctaButton[0]}
    />
  );
};

export default CtaButton;
