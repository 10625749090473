import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: "max-content",
      margin: "0 auto",
      padding: "40px 20px",
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        padding: "60px 34px",
      },
    },
    title: {
      margin: "0 0 26px",
    },
    subtitle: {
      color: theme.palette.secondary.main,
    },
    btnContainer: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 0 50px",
      [theme.breakpoints.up("sm")]: {
        margin: "0 0 60px",
      },
      [theme.breakpoints.up("lg")]: {
        margin: "0",
      },
    },
    text: {
      margin: "0 0 34px",
      [theme.breakpoints.up("sm")]: {
        margin: "0 0 60px",
      },
      [theme.breakpoints.up("md")]: {
        margin: "0 0 74px",
      },
    },
  });
});
