import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "../card";
import { useStyles } from "./secureGuarantee.styles";
import CardGrid from "../cardGrid";
import RapidSSL from "./rapid-ssl.svg";
import lockImage from "./lock-image.png";

const SecureGuarantee = () => {
  const classes = useStyles();
  return (
    <CardGrid>
      <Card className={classes.card}>
        <div className={classes.ssl}>
          <RapidSSL />
        </div>
        <div className={classes.lock}>
          <img style={{ height: 72 }} src={lockImage} alt="" />
        </div>
        <div>
          <Typography variant="h3" component="h4">
            100% Secure Purchase
          </Typography>
          <Typography variant="body1" component="p">
            You transaction detail are transmitted over a secure channel to our
            PCI-compliant paymant processors.
          </Typography>
        </div>
      </Card>
    </CardGrid>
  );
};

export default SecureGuarantee;
