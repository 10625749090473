import React from "react";
import CardGrid from "../cardGrid";
import InstructionWidget from "../instructionWidget";

interface IInstruction {
  heading: string;
  subheading: string;
  text: IContent;
}

interface IPropsBlok extends IBlok {
  heading: string;
  instructions: [IInstruction, IInstruction, IInstruction];
}

interface IProps {
  blok: IPropsBlok;
}

const GridOfInstructions = ({ blok }: IProps) => {
  return (
    <CardGrid id={blok.anchorTag} heading={blok.heading}>
      {blok.instructions.map(({ heading, subheading, text }: IInstruction) => (
        <InstructionWidget
          heading={heading}
          subheading={subheading}
          text={text}
        />
      ))}
    </CardGrid>
  );
};

export default GridOfInstructions;
