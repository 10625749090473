import React from "react";

type Props = {
  component: string;
  children: JSX.Element | JSX.Element[] | null | undefined;
};

type State = {
  errorMessage: string | null;
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    errorMessage: null,
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const activeEnv = process.env.GATSBY_ACTIVE_ENV || "development";

      if (activeEnv === "development") {
        return (
          <h1
            style={{
              textAlign: "center",
              padding: "3rem 0",
              color: "#FF5454",
            }}
          >
            {this.props.component} has thrown an error.
          </h1>
        );
      } else {
        return null;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
