import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import Seo from "./seo";
import "../../static/fonts/MuseoSans/stylesheet.css";
import "../../static/fonts/SabonCry/stylesheet.css";
import "../../static/fonts/RobotoSlabs/stylesheet.css";
import useResponsiveTheme from "../hooks/useResponsiveTheme";

interface IProps {
  children: React.ReactNode;
  favicon: string;
  storyData?: Object | null;
  globalSeo?: ISeo | undefined;
  pageSeo?: ISeo | undefined;
  disablePageCrawl: boolean | undefined;
}

const Layout = ({
  children,
  favicon,
  storyData,
  globalSeo,
  pageSeo,
  disablePageCrawl,
}: IProps) => {
  const theme = useResponsiveTheme();

  const setFavIcon = (src: string | undefined) => {
    if (!src) return;
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "icon";
    link.href = src;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  const seoHandler = (
    globalSeo: ISeo | undefined,
    pageSeo: ISeo | undefined
  ) => {
    const newSeoObject: ISeo = {} as ISeo;

    const newSeo = pageSeo || globalSeo;

    if (newSeo) {
      for (const [key] of Object.entries(newSeo)) {
        const obKey = key as SeoType;
        if (pageSeo) {
          newSeoObject[obKey] = pageSeo[obKey];
        } else if (globalSeo) {
          newSeoObject[obKey] = globalSeo[obKey];
        }
      }
      return newSeoObject;
    }
    return;
  };

  useEffect(() => {
    setFavIcon(favicon);
  }, [favicon]);

  const seo = seoHandler(globalSeo, pageSeo);

  return (
    <main>
      {seo && (
        <Seo
          seo={seo}
          storyData={storyData}
          disablePageCrawl={disablePageCrawl}
        />
      )}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </main>
  );
};

export default Layout;
