import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    gradientEffect: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(90deg, #FFFFFF 1.93%, rgba(255, 255, 255, 0) 64.82%)",
      opacity: 0.4,
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {
        backgroundPosition: "50% 25%",
      },
    },
    container: {
      position: "relative",
      backgroundSize: "1366px auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "20% 0%",
      marginBottom: "24px",
      [theme.breakpoints.up("sm")]: {
        backgroundSize: "1920px auto",
        backgroundPosition: "10% 0%",
        padding: "0 68px",
      },
      [theme.breakpoints.up("md")]: {
        backgroundPosition: "66% 0%",
        backgroundSize: "cover",
        marginBottom: "36px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "100vh",
        backgroundPosition: "50% 25%",
        marginBottom: "75px",
      },
      // imported from funky black-friday-2021-styles.css
      ['@media screen and (max-width: 1200px)']:{
        backgroundPosition: "55% 12%",
      },
      ['@media screen and (max-width: 600px)']:{
        backgroundPosition: "66% 10%",
      },
      ['@media screen and (max-width: 320px)']:{
        backgroundPosition: "64% 20%",
      },
    },
    contentWrapper: {
      position: "relative",
      zIndex: 1,
      width: "100%",
      textAlign: "center",
      marginTop: "5%",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
        maxWidth: "700px",
      },
      [theme.breakpoints.up("lg")]: {
        textAlign: "left",
        maxWidth: "900px",
      },
    },
    preHeading: {
      color: "#fff",
      marginLeft: "auto",
      marginRight: "auto",
      width: "fit-content",
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    heading: {
      color: "#fff",
      marginLeft: "auto",
      marginRight: "auto",
      margin: "60px auto 40px",
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    subheading: {
      color: "#fff",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "40px",
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("xl")]: {
        marginBottom: "100px",
      },
    },
    disclaimerText: {
      color: "#fff",
      marginTop: "40px",
      paddingBottom: "20px",
      fontStyle: "italic",
    },
  });
});
