import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    linkContainer: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "480px",
      margin: "0 auto",
    },
    link: {
      height: "64px",
      width: "64px",
      [theme.breakpoints.up("md")]: {
        height: "72px",
        width: "72px",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      backgroundColor: "rgba(75, 153, 156, 0.08)",
      color: theme.palette.tertiary.main,
    },
  });
});
