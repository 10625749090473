import React, { useState, useRef, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./asFeaturedIn.styles";
import smoothscroll from "smoothscroll-polyfill";
import LeftArrowIcon from "./leftArrow.png";
import RightArrowIcon from "./rightArrow.png";

interface ILogo extends IBlok {
  image: IAsset;
}

interface IPropsBlok extends IBlok {
  heading: string;
  logos: Array<ILogo>;
}

interface IProps {
  blok: IPropsBlok;
}

const AsFeaturedIn = ({ blok }: IProps) => {
  const [autoScroll, setAutoScroll] = useState<null | NodeJS.Timer>(null);
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const scrollBox = useRef({} as HTMLDivElement);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const scroll = (scrollOffset: number, direction: "left" | "right") => {
    if (
      direction === "right" &&
      scrollBox?.current.scrollLeft >=
        scrollBox?.current.scrollWidth - scrollBox?.current.clientWidth
    ) {
      scrollBox.current.scrollLeft = 0;
    } else if (direction === "left" && scrollBox?.current.scrollLeft <= 0) {
      scrollBox.current.scrollLeft =
        scrollBox?.current.scrollWidth - scrollBox?.current.clientWidth;
    }
    scrollBox?.current.scrollBy({ left: scrollOffset, behavior: "smooth" });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      scroll(306, "right");
    }, 3000);
    setAutoScroll(interval);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      data-testid="asFeaturedIn"
      id={blok.anchorTag}
      className={classes.container}
    >
      <Typography variant="h3" component="h3">
        {blok.heading}
      </Typography>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {tablet && (
          <div className={classes.scrollControlsWrapper}>
            <div
              className={classes.scrollButtonWrapper}
              style={{
                background:
                  "linear-gradient(90deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%)",
              }}
            >
              <Button
                className={classes.scrollButton}
                onMouseDown={() => {
                  if (autoScroll) clearInterval(autoScroll);
                  scroll(-300, "left");
                }}
              >
                <img src={LeftArrowIcon} />
              </Button>
            </div>
            <div
              className={classes.scrollButtonWrapper}
              style={{
                background:
                  "linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, #FAFAFA)",
              }}
            >
              <Button
                className={classes.scrollButton}
                onMouseDown={() => scroll(300, "right")}
              >
                <img src={RightArrowIcon} />
              </Button>
            </div>
          </div>
        )}
        <Container
          ref={scrollBox}
          className={tablet ? classes.scrollbox : classes.grid}
          style={{
            scrollbarWidth: "none",
          }}
        >
          {blok.logos.map(({ image }) => (
            <div
              className={`${
                tablet ? classes.scrollImageWrapper : classes.imageWrapper
              } logo`}
              key={image.id}
            >
              <img className={classes.image} src={image.filename} />
            </div>
          ))}
        </Container>
      </div>
    </Box>
  );
};

export default AsFeaturedIn;
