import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { useLocation } from "@reach/router";
import { useStyles } from "./footer.styles";

interface ILinkComponent extends IBlok {
  text: string;
  link: ILink;
}

interface IPropsBlok extends IBlok {
  copyright: string;
  navigationLinks: ILinkComponent[];
}

interface IProps {
  blok: IPropsBlok;
}

const Footer = ({ blok }: IProps) => {
  const location = useLocation();
  const classes = useStyles();

  return (
    <Box id={blok.anchorTag} className={classes.footer}>
      <Container className={classes.wrapper}>
        <Typography variant="body1" component="p" className={classes.copy}>
          {blok?.copyright}
        </Typography>
        <div>
          {blok.navigationLinks.map(({ _uid, link, text }, index: number) => {
            const url = link?.url || `${location.origin}/${link?.cached_url}`;

            return (
              <React.Fragment key={_uid}>
                {index > 0 && <span>&bull;</span>}
                <a href={url} className={classes.footerLink}>
                  {text}
                </a>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </Box>
  );
};

export default Footer;
