import React from "react";
import Container from "@material-ui/core/Container";
import { useStyles } from "./twoColumnSection.styles";

interface ISection {
  id?: string;
  mirror?: boolean;
  toggleStackOrder?: boolean;
  left: JSX.Element;
  right: JSX.Element;
}

const TwoColumnSection = ({
  id,
  mirror,
  toggleStackOrder,
  left,
  right,
}: ISection) => {
  const classes = useStyles();

  return (
    <Container
      id={id}
      style={{
        // used for dynamic styles
        flexDirection: mirror ? "row-reverse" : "row",
        flexWrap: toggleStackOrder ? "wrap-reverse" : "wrap",
      }}
      className={classes.section}
    >
      <div className={classes.column}>{left}</div>
      <div className={classes.column}>{right}</div>
    </Container>
  );
};

export default TwoColumnSection;
