import React from "react";
import Typography from "@material-ui/core/Typography";
import Vimeo from "../vimeo";
import Paragraphs from "../paragraphs";
import CtaButton from "../ctaButton";
import TwoColumnSection from "../twoColumnSection";
import { IContent } from "../paragraphs";

interface IPropsBlok extends IBlok {
  videoCaption: string;
  videoUrl: string;
  heading: string;
  paragraphs: IContent;
  ctaButton: [ICtaButton];
}

interface IProps {
  blok: IPropsBlok;
}

const TwoColumnVideoCta = ({ blok }: IProps) => {
  return (
    <TwoColumnSection
      id={blok.anchorTag}
      left={
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {blok.videoCaption && (
            <Typography
              variant="h5"
              component="h5"
              style={{
                maxWidth: "100%",
                textAlign: "center",
                marginBottom: "24px",
              }}
            >
              {blok.videoCaption}
            </Typography>
          )}
          <Vimeo videoUrl={blok.videoUrl} />
        </div>
      }
      right={
        <div
          style={{
            maxWidth: 900,
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            style={{
              padding: "0 0 24px",
              textAlign: "left",
            }}
          >
            {blok?.heading}
          </Typography>
          <Paragraphs paragraphs={blok?.paragraphs} />
          <div
            style={{
              padding: "30px 0",
              width: "100%",
              maxWidth: "768px",
            }}
          >
            {<CtaButton ctaButton={blok.ctaButton} />}
          </div>
        </div>
      }
    />
  );
};

export default TwoColumnVideoCta;
