import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface IProps {
  seo: ISeo;
  storyData?: Object;
  disablePageCrawl: boolean | undefined;
}

interface IScript {
  scriptName: string;
  scriptEmbed: string;
}

const Seo: React.FC<IProps> = ({ seo, storyData, disablePageCrawl }) => {
  const [headScripts, setHeadScripts] = useState([]);
  const data = useStaticQuery(graphql`
    query {
      allStoryblokEntry(filter: { slug: { in: ["global"] } }) {
        edges {
          node {
            content
          }
        }
      }
    }
  `);
  const content = JSON.parse(data.allStoryblokEntry.edges[0].node.content);
  const scripts = storyData?.content?.scripts?.scripts;

  useEffect(() => {
    const result = content.headScripts.map(
      ({ scriptName, scriptEmbed }: IScript) => {
        if (scripts && scripts[scriptName]) {
          const scriptCode = scriptEmbed
            .split(/(?:<script>|<\/script>)/)
            .join("")
            .replaceAll(/<!--(.*)-->/g, ""); // removes html comments from the string

          return scriptCode;
        }
      }
    );
    setHeadScripts(result);
  }, []);

  useEffect(() => {
    const result = content.bodyScripts.map(
      ({ scriptName, scriptEmbed }: IScript) => {
        if (scripts && scripts[scriptName]) {
          const parser = new DOMParser();

          const script = parser.parseFromString(scriptEmbed, "text/html");

          return script.getElementsByTagName("body")[0].children[0];
        }
      }
    );

    result.forEach((script: Element | undefined | null) => {
      if (script) {
        if (script.tagName === "SCRIPT") {
          document.body.prepend(script);
        }
        const noscript = document.createElement("noscript");
        noscript.append(script);
        document.body.prepend(noscript);
      }
    });
  }, []);

  const { favicon } = JSON.parse(data.allStoryblokEntry.edges[0].node.content);
  return (
    <Helmet
      defer={false}
      defaultTitle={seo?.title}
      title={seo?.title}
      titleTemplate={seo?.title}
    >
      {headScripts.map((script) => (
        <script>{script}</script>
      ))}
      <html lang={"en"} />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="description" content={seo?.description} />
      {favicon && (
        <link
          rel="icon"
          type="image/png"
          href={favicon.filename}
          sizes="16x16"
        />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={"en"} />
      <meta property="og:site_name" content={seo?.title} />
      <meta property="og:image" content={seo?.og_image} />
      <meta property="og:description" content={seo?.description} />
      <meta name="twitter:card" content={seo?.twitter_title} />
      <meta name="twitter:image" content={seo?.twitter_image} />
      <meta property="twitter:description" content={seo?.twitter_description} />
      {disablePageCrawl && <meta name="robots" content="noindex,nofollow" />}
    </Helmet>
  );
};

export default Seo;
