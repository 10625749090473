import React from "react";
import Img from "gatsby-image";
import { Typography } from "@material-ui/core";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import TwoColumnSection from "../twoColumnSection";
import Bullet from "../bullet";
import { useStyles } from "./imageAndBulletsCta.styles";
import CtaButton from "../ctaButton";
import { StylesContext } from "@material-ui/styles";

interface IBullet extends IBlok {
  text: IContent;
}

interface IPropsBlok extends IBlok {
  image: IAsset;
  heading: string;
  bullets: Array<IBullet>;
  ctaButton: [ICtaButton];
}

interface IProps {
  blok: IPropsBlok;
}

const ImageAndBullets = ({ blok }: IProps) => {
  const classes = useStyles();
  const fluidProps = getFluidGatsbyImage(blok.image.filename, {
    maxWidth: 900,
  });
  return (
    <TwoColumnSection
      id={blok.anchorTag}
      left={
        <>
          <Typography variant="h3" component="h3">
            {blok.heading}
          </Typography>
          <div className={classes.bulletsWrapper}>
            {blok.bullets.map((bullet) => (
              <Bullet text={bullet.text} key={bullet._uid} />
            ))}
          </div>
          <div className={classes.btnWrapper}>
            <CtaButton ctaButton={blok.ctaButton} />
          </div>
        </>
      }
      right={<Img alt={blok.image.alt} fluid={fluidProps} />}
    />
  );
};

export default ImageAndBullets;
