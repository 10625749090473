import React from "react";
import CardGrid from "../cardGrid";
import PaymentCard from "../paymentCard";

interface IPaymentCard {
  preheading: string;
  heading: string;
  subheading: string;
  price: number;
  frequency: string;
  ctaButton: Array<ICtaButton>;
}

interface IPropsBlok extends IBlok {
  heading: string;
  subheading: string;
  priceOptions: IPaymentCard[];
}

interface IProps {
  blok: IPropsBlok;
}

const PaymentOptions = ({ blok }: IProps) => {
  return (
    <CardGrid
      id={blok.anchorTag}
      heading={blok.heading}
      subheading={blok.subheading}
    >
      {blok.priceOptions.map((option: IPaymentCard, index: number) => (
          <PaymentCard blok={option} variant={index < blok.priceOptions.length - 1 ? 'default' : 'alt'} />
        )
      )}
    </CardGrid>
  );
};

export default PaymentOptions;
