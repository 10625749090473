import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "24px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0",
      },
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "768px",
      margin: "0 auto",
    },
    heading: {
      textAlign: "center",
      marginBottom: "50px",
      [theme.breakpoints.up("lg")]: {
        marginBottom: "80px",
      },
    },
    textContent: {
      marginBottom: "40px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "60px",
      },
    },
  });
});
