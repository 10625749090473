import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./specialOfferBanner.styles";

interface IProps {
  blok: {
    text: string;
  };
}

const SpecialOfferBanner = ({ blok }: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Typography className={styles.text} variant="body2" component="p">
        {blok.text}
      </Typography>
    </div>
  );
};

export default SpecialOfferBanner;
