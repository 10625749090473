import React from "react";
import Logo from "../logo";
import { Typography, Container } from "@material-ui/core";
import CtaButton from "../ctaButton";
import { useStyles } from "./videoHero.styles";
import Vimeo from "../vimeo";

interface IPropBlok extends IBlok {
  logo: IAsset;
  preheading: string;
  heading: string;
  subheading: string;
  videoUrl: string;
  ctaButton: [ICtaButton];
  disclaimerText?: string;
}

interface IProps {
  blok: IPropBlok;
}

function VideoHero({ blok }: IProps) {
  const classes = useStyles();
  return (
    <Container>
      {(blok?.logo?.filename) ? <Logo filename={blok?.logo?.filename} /> : <div className={classes.logoSpacer}></div>}
      <div className={classes.container}>
        <Typography variant="h3" component="h3" className={classes.preheading}>
          {blok.preheading}
        </Typography>
        <Typography variant="h1" component="h2" className={classes.heading}>
          {blok.heading}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={classes.subheading}
        >
          {blok.subheading}
        </Typography>
        <Vimeo
          style={{
            width: "100%",
            margin: "0 auto 23px",
          }}
          videoUrl={blok.videoUrl}
        />
        <CtaButton ctaButton={blok.ctaButton} />
        {blok?.disclaimerText && (
          <Typography
            style={{
              margin: "24px 0 0",
              fontStyle: "italic",
            }}
          >
            {blok.disclaimerText}
          </Typography>
        )}
      </div>
    </Container>
  );
}

export default VideoHero;
