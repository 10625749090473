import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      backgroundColor: "#fafafa",
      textAlign: "center",
      padding: "60px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "60px 24px",
      },
    },
    timer: {
      maxWidth: 1400,
      padding: "7.5px 10px",
      margin: "40px auto 0",
      [theme.breakpoints.up("md")]: {
        padding: "16px 78px",
        marginTop: 46,
      },
      [theme.breakpoints.up("lg")]: {
        padding: "27px 0 17px",
        marginTop: 60,
      },
      display: "flex",
      justifyContent: "space-evenly",
    },
  });
});
