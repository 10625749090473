import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "column",
      },
      textAlign: "left",
    },
    ssl: {
      position: "absolute",
      top: 30,
      right: 30,
    },
    lock: {
      backgroundColor: "rgba(75, 153, 156, 0.08)",
      height: 106,
      width: 106,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      marginRight: 39,
      marginBottom: 34,
    },
  });
});
