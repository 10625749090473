import React from "react";
import Container from "@material-ui/core/Container";
import { useStyles } from "./oneColumnSection.styles";

interface IProps {
  id?: string;
  backgroundStyle?: "default" | "alternate" | "dark";
  widthStyle: "default" | "text";
}

const OneColumnSection: React.FC<IProps> = ({
  id,
  backgroundStyle,
  widthStyle,
  children,
}) => {
  const classes = useStyles();

  const getContentWidth = () => {
    switch (widthStyle) {
      case "default":
        return "100%";
      case "text":
        return "900px";
    }
  };
  const getBgColor = () => {
    switch (backgroundStyle) {
      case "default":
        return classes.bgDefault;
      case "alternate":
        return classes.bgAlt;
      case "dark":
        return classes.bgDark;
      default:
        return classes.bgDefault;
    }
  };

  const contentWidth = getContentWidth();
  const bgColor = getBgColor();

  return (
    <div className={bgColor}>
      <Container id={id} className={classes.container}>
        <div
          className={classes.contentWrapper}
          style={{
            maxWidth: contentWidth,
          }}
        >
          {children}
        </div>
      </Container>
    </div>
  );
};

export default OneColumnSection;
