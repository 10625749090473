import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      textAlign: "center",
      backgroundColor: "#fff",
      padding: "30px",
      width: "100%",
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      borderRadius: "10px",
    },
  });
});
