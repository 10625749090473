import React from "react";
import Typography from "@material-ui/core/Typography";
import Paragraphs from "../paragraphs";
import { IContent } from "../paragraphs";
import { useStyles } from "./testimonialWidget.styles";

interface ITestimonialWidget {
  content: IContent;
  authorName: string;
}

const TestimonialWidget = ({ content, authorName }: ITestimonialWidget) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <Paragraphs paragraphs={content} />
        <Typography className={classes.authorName} variant="h4" component="h4">
          {authorName}
        </Typography>
      </div>
    </div>
  );
};

export default TestimonialWidget;
