import React from 'react';
import Card from '../card';
import Typography from '@material-ui/core/Typography';
import Paragraphs from "../paragraphs";
import CtaButton from "../ctaButton";
import { useStyles } from "./productWidget.styles";

interface IProps {
    image: IAsset;
    heading: string;
    description: IContent;
    ctaButton: ICtaButton[];
    moreButton: ICtaButton[];
}

const ProductWidget = ({image, heading, description, ctaButton, moreButton}: IProps) => {
    const styles = useStyles();
    return (
        <Card className={styles.card}>
            <img src={image.filename} alt={image.alt} className={styles.cardImage} />
            <Typography variant="h4" component="h4">{heading}</Typography>
            <Paragraphs paragraphs={description} />
            <div className={styles.buttonContainer}>
                {
                    (ctaButton.length > 0) ? <CtaButton ctaButton={ctaButton}/> : null
                }
                {
                    (moreButton.length > 0) ? <CtaButton ctaButton={moreButton} variant="alt" className={styles.moreButton} /> : null
                }
            </div>
        </Card>
    );
};

export default ProductWidget;