import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      maxWidth: 900,
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      flex: "100%",
      [theme.breakpoints.up("lg")]: {
        flex: 1,
        margin: "10px 10px 0",
      },
    },
    subheading: {
      color: theme.palette.secondary.main,
    },
    textWrapper: {
      minHeight: "90px",
      [theme.breakpoints.up("md")]: {
        minHeight: "74px",
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: "110px",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "#fafafa",
      padding: "0 1rem",
      marginTop: "30px",
    },
  });
});
