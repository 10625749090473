import React from "react";
import { useStyles } from "./logo.styles";

interface ILogo {
  filename: string;
  centered?: boolean;
}

const Logo = ({ filename, centered }: ILogo) => {
  const classes = useStyles();

  return (
    <div
      className={classes.logoWrapper}
      style={{ justifyContent: centered ? "center" : "initial" }}
    >
      <img className={classes.logo} src={filename} alt="logo" />
    </div>
  );
};

export default Logo;
