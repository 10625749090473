import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import CtaButton from "../ctaButton";
import { useStyles } from "./popUpCta.styles";

interface IPropsBlok extends IBlok {
  preheading: string;
  heading: string;
  text: string;
  ctaButton: [ICtaButton];
}

interface IProps {
  blok: IPropsBlok;
}

const PopUpCta = ({ blok }: IProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const classes = useStyles();
  return (
    <Modal open={isOpen}>
      <div className={classes.container}>
        <Button className={classes.btn} onClick={() => setIsOpen(false)}>
          <Typography
            style={{
              color: "#fff",
            }}
            variant="h1"
            component="span"
          >
            &times;
          </Typography>
        </Button>
        <Typography
          className={classes.preheading}
          variant="body2"
          component="h4"
        >
          {blok.preheading}
        </Typography>
        <Typography style={{ color: "#fff" }} variant="h2" component="h2">
          {blok.heading}
        </Typography>
        <Typography className={classes.text} variant="body2" component="h2">
          {blok.text}
        </Typography>
        <div
          style={{
            padding: "30px 0",
            width: "100%",
            maxWidth: "768px",
          }}
        >
          {<CtaButton ctaButton={blok.ctaButton} />}
        </div>
      </div>
    </Modal>
  );
};

export default PopUpCta;
