import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    heading: {
      margin: "0 auto 50px",
      [theme.breakpoints.up("lg")]: {
        margin: "0 auto 80px",
      },
    },
    card: {
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      marginBottom: 20,
      borderRadius: 6,
      padding: "40px 30px",
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "60px 50px",
      },
    },
    cardHeading: {
      marginBottom: 24,

      [theme.breakpoints.up("lg")]: {
        marginBottom: 34,
      },
    },
    cardPreheading: {
      color: theme.palette.secondary.dark,
    },
    cardImg: {
      borderRadius: 6,
      width: "100%",
      maxWidth: 300,
      marginBottom: "24px",
      [theme.breakpoints.up("sm")]: {
        padding: "0",
        marginBottom: "0",
        marginLeft: "50px",
      },
      [theme.breakpoints.up("lg")]: {
        maxHeight: 280,
      },
    },
  });
});
