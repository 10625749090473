import React from "react";
import { Typography } from "@material-ui/core";
import OneColumnSection from "../oneColumnSection";
import { useStyles } from "./bonusesSection.styles";

interface ICard {
  preheading: string;
  heading: string;
  content: string;
  image: IAsset;
}

interface IProps {
  blok: {
    heading: string;
    bonusCards: ICard[];
  };
}

function BonusesSection({ blok }: IProps) {
  const classes = useStyles();
  return (
    <OneColumnSection widthStyle="default" backgroundStyle="alternate">
      <Typography className={classes.heading} variant="h3" component="h3">
        {blok.heading}
      </Typography>
      {blok.bonusCards.map((card) => (
        <div className={classes.card}>
          <div>
            <Typography
              className={classes.cardPreheading}
              variant="h4"
              component="h4"
            >
              {card.preheading}
            </Typography>
            <Typography
              className={classes.cardHeading}
              variant="h3"
              component="h3"
            >
              {card.heading}
            </Typography>
            <Typography
              style={{
                maxWidth: "768px",
              }}
              variant="body1"
              component="p"
            >
              {card.content}
            </Typography>
          </div>
          <img className={classes.cardImg} src={card.image.filename} />
        </div>
      ))}
    </OneColumnSection>
  );
}

export default BonusesSection;
