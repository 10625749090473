import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import TwoColumnSection from "../twoColumnSection";
import Bullet from "../bullet";
import { useStyles } from "./imageAndBullets.styles";

interface IBullet extends IBlok {
  text: IContent;
}

interface IPropsBlok extends IBlok {
  mirror?: boolean;
  toggleStackOrder?: boolean;
  image: IAsset;
  bullets: Array<IBullet>;
}

interface IProps {
  blok: IPropsBlok;
}

const ImageAndBullets = ({ blok }: IProps) => {
  const classes = useStyles();
  const fluidProps = getFluidGatsbyImage(blok.image.filename, {
    maxWidth: 900,
  });
  return (
    <TwoColumnSection
      id={blok.anchorTag}
      mirror={blok.mirror}
      toggleStackOrder={blok.toggleStackOrder}
      left={
        <Img
          className={classes.image}
          alt={blok.image.alt}
          fluid={fluidProps}
        />
      }
      right={
        <div className={classes.bulletsWrapper}>
          {blok.bullets.map((bullet) => (
            <Bullet text={bullet.text} key={bullet._uid} />
          ))}
        </div>
      }
    />
  );
};

export default ImageAndBullets;
