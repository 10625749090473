import React, { useEffect } from "react";
import { StoryData } from "storyblok-js-client";
import { PageProps } from "gatsby";
import { SbEditableContent } from "storyblok-react";
import DynamicComponent from "../components/dynamicComponent";
import useStoryblok from "../lib/storyblok";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Layout from "../components/layout";
import { graphql, navigate } from "gatsby";

interface IProps extends PageProps {
  pageContext: { story: StoryData; global: StoryData };
  data: {
    allStoryblokEntry: any;
  };
}

const Page: React.FC<IProps> = ({ pageContext, data, location }) => {
  const story = pageContext.story;
  const storyData = useStoryblok(story, location);
  const theme = useTheme();
  const isAllDevices = useMediaQuery(theme.breakpoints.up("xs"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet =
    useMediaQuery(theme.breakpoints.between("sm", "lg")) && !isDesktop;
  const isMobile =
    useMediaQuery(theme.breakpoints.between("xs", "sm")) && !isTablet;
  const devices = [isAllDevices, isMobile, isTablet, isDesktop];

  useEffect(() => {
    const { hash, search } = location;
    if (
      storyData?.content?.redirect?.linktype === "story" &&
      storyData?.content?.redirect?.cached_url
    ) {
      navigate("/" + storyData.content.redirect.cached_url + search);
    } else if (
      storyData?.content?.redirect?.linktype === "url" &&
      storyData?.content?.redirect?.url
    ) {
      window.location.href = storyData.content.redirect.url + search;
    }
    if (hash) {
      navigate(`${search}${hash}`);
    }
  }, []);

  const global = data?.allStoryblokEntry?.edges?.find(
    (e: any) => e.node.slug === "global"
  );
  const globalData = global && useStoryblok(global?.node, location);

  const favicon = global?.content?.favIcon?.filename;

  const renderDynamicComponents = (components: SbEditableContent[]) => {
    const enabledBlocks = storyData?.content?.disableBlocks?.enabledBlock;
    if (!components) return;
    return components?.map((component) => {
      if (
        (enabledBlocks?.hasOwnProperty(component._uid) &&
          !enabledBlocks[component._uid]) ||
        (component?.targetDevice && !devices[component.targetDevice])
      ) {
        return null;
      }
      return (
        <React.Fragment key={component._uid}>
          <DynamicComponent blok={component} />
        </React.Fragment>
      );
    });
  };

  const components = storyData?.content?.body;

  return (
    <Layout
      favicon={favicon}
      storyData={storyData}
      pageSeo={storyData?.content?.seo}
      globalSeo={globalData?.content?.seo}
      disablePageCrawl={storyData?.content?.disablePageCrawl}
    >
      {components && storyData ? renderDynamicComponents(components) : <div />}
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query GlobalQuery {
    allStoryblokEntry(filter: { slug: { in: ["global"] } }) {
      edges {
        node {
          content
          name
          slug
        }
      }
    }
  }
`;
