import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      textAlign: "center",
      width: "100%",
      padding: "24px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0",
      },
    },
    videoWrapper: {
      width: "100%",
      margin: "40px 0",
      [theme.breakpoints.up("md")]: {
        margin: "60px 0",
      },
    },
  });
});
