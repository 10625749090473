import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      width: "100%",
      maxWidth: "900px",
      display: "flex",
      alignItems: "center",
      padding: "24px 30px 24px 15px",
      backgroundColor: "#fff",
      marginBottom: "12px",
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      borderRadius: "10px",
      [theme.breakpoints.up("sm")]: {
        padding: "24px 30px",
      },
    },
    iconWrapper: {
      padding: "15px",
      marginRight: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(75, 153, 156, 0.08)",
      borderRadius: "50%",

      [theme.breakpoints.up("sm")]: {
        marginRight: "30px",
      },
    },
  });
});
