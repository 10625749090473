import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import Img from "gatsby-image";
import Vimeo from "../vimeo";
import Paragraphs from "../paragraphs";
import SocialMediaLinks from "../socialMediaLinks";
import CtaButton from "../ctaButton";
import TwoColumnSection from "../twoColumnSection";
import { IContent } from "../paragraphs";
import { ISocialMediaLinks } from "../socialMediaLinks";
import { useStyles } from "././mediaAndLeadContent.styles";

interface IAssetComponent extends IBlok {
  image: IAsset;
}

interface IVimeoComponent extends IBlok {
  videoUrl: string;
}

interface IPropsBlok extends IBlok {
  mirror: boolean;
  toggleStackOrder: boolean;
  mediaHeading: string;
  media: unknown[];
  heading: string;
  contentImage: IAsset;
  paragraphs: IContent;
  socialMediaLinks: ISocialMediaLinks[];
  ctaButton?: [ICtaButton];
  optionalCtaButtons?: string[];
  OptionalCtaText?: string;
  optionalCtaLink?: ILink;
  openInSameTab?: boolean;
}

interface IProps {
  blok: IPropsBlok;
}

const MediaAndLeadContent = ({ blok }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [media] = blok.media;

  const fluidProps = getFluidGatsbyImage(
    (media as IAssetComponent)?.image?.filename,
    {
      maxWidth: 900,
    }
  );

  return (
    <TwoColumnSection
      id={blok.anchorTag}
      mirror={blok?.mirror}
      toggleStackOrder={blok?.toggleStackOrder}
      left={
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {blok.mediaHeading && (
            <Typography
              variant="h5"
              component="h5"
              style={{
                maxWidth: "100%",
                textAlign: "center",
                marginBottom: "24px",
              }}
            >
              {blok.mediaHeading}
            </Typography>
          )}
          {(media as IBlok)?.component === "Image" && (
            <Img
              alt={(media as IAssetComponent)?.image?.alt}
              fluid={fluidProps}
              style={{
                maxWidth: 900,
                marginBottom: isMobileDevice ? 30 : 0,
              }}
            />
          )}
          {(media as IBlok)?.component === "vimeo" && (
            <div
              style={{
                width: "100%",
                maxWidth: "768px",
                flexBasis: "1",
              }}
            >
              <Vimeo videoUrl={(media as IVimeoComponent).videoUrl} />
            </div>
          )}
          {blok?.ctaButton && blok?.ctaButton?.length > 0 && (
            <div className={classes.btnWrapper}>
              <CtaButton ctaButton={blok.ctaButton} />
            </div>
          )}
        </div>
      }
      right={
        <div
          style={{
            maxWidth: 900,
            margin: "0 auto",
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            style={{
              padding: "0 0 24px",
              textAlign: "left",
            }}
          >
            {blok?.heading}
          </Typography>
          <Paragraphs paragraphs={blok?.paragraphs} />
          {blok?.contentImage && <img src={blok?.contentImage.filename} />}
          {blok?.socialMediaLinks?.length > 0 && (
            <SocialMediaLinks blok={{ ...blok?.socialMediaLinks[0] }} />
          )}
        </div>
      }
    />
  );
};

export default MediaAndLeadContent;
