import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      textAlign: "center",
      padding: "60px 30px 30px",
      flex: 1,
      width: '100%',

      '& h4': {
        color: theme.palette.secondary.contrastText,
      },

      '& $price': {
        color: theme.palette.secondary.main,
      },
    },
    cardAlt: {
      background: "linear-gradient(130.23deg, #EAB54D 5.89%, #DD723F 101.19%)",
      textAlign: "center",
      padding: "60px 30px 30px",
      flex: 1,
      width: '100%',

      '& h4': {
        color: theme.palette.text.secondary,
      },

      '& h3': {
        color: theme.palette.text.secondary,
      },

      '& p': {
        color: theme.palette.text.secondary,
      },
    },
    price: {
      display: "inline-block",
      marginBottom: 50,
    },
    linkWrapper: {
      textDecoration: "none",
      width: "100%",
      display: 'block',
    },
    heading: {
      marginBottom: 4,
      [theme.breakpoints.up("lg")]: {
        marginBottom: 24,
      },
    },
  });
});
