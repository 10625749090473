import { useEffect } from "react";

const useEmbed = (element: HTMLElement, newLocationId?: string) => {
  useEffect(() => {
    if (isValidToEmbed(element)) {
      document.body.appendChild(element);
      if (newLocationId) {
        document.getElementById(newLocationId)?.appendChild(element);
      }

      return () => {
        document.body.removeChild(element);
      };
    }
  }, []);
};

// Ensure that the embed provided contains references to scripts that are
//  either not either loading via HTTPS or one of our local scripts
function isValidToEmbed (element: HTMLElement) {
  let elementToCheck;
  if (element instanceof HTMLDivElement) {
    const findScript = element.querySelector('script');
    elementToCheck = findScript ? (findScript as HTMLScriptElement) : undefined;
  } else if (element instanceof HTMLScriptElement) {
    elementToCheck = element as HTMLScriptElement;
  }

  let isValid = false;
  if (elementToCheck) {
    const src = elementToCheck.src;
    isValid = src.startsWith("https") || src.startsWith("/local-scripts");
  }

  return isValid;
}

export default useEmbed;
