import React from "react";
import Container from "@material-ui/core/Container";
import Logo from "../logo";
import { useStyles } from "./basicHeader.styles";

interface IProps {
  blok: {
    logo: IAsset;
  };
}

const BasicHeader = ({ blok }: IProps) => {
  const classes = useStyles();
  return (
    <header className={classes.container}>
      <Container>
        <Logo filename={blok.logo.filename} />
      </Container>
    </header>
  );
};

export default BasicHeader;
