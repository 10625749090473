import React, { useEffect } from "react";
import { useStyles } from "./link.styles";
import smoothscroll from "smoothscroll-polyfill";

interface ILink {
  url: string;
  anchor: string;
  linkType: string;
  target: string;
}

const LinkComponent: React.FC<ILink> = ({
  url,
  anchor,
  linkType,
  target,
  children,
}) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const scrollToAnchor = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    /*
    if (
        linkType === "story" &&
        // `/${cached_url}` === location.pathname
        `${url}` === location.pathname
    ) {
    */
    if (anchor &&
        linkType === "story") {
      e.preventDefault();
      const element = document.getElementById(anchor);
      if (element) {
        window.scrollTo({top: element.offsetTop, behavior: "smooth"});
      }
    }
  };

  if (linkType === "story") {
    return (
        <a
            className={classes.link}
            data-testid="link--link"
            onClick={scrollToAnchor}
            // href={"/" + cached_url}
            href={url}
            target={target}
        >
          {children}
        </a>
    );
  } else {
    return (
        <a
            className={classes.link}
            data-testid="link--anchor"
            href={linkType === "email" ? `mailto:${url}` : url}
            target={target}
        >
          {children}
        </a>
    );
  }
};

export default LinkComponent;
