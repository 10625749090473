import React from "react";
import Typography from "@material-ui/core/Typography";
import OneColumnSection from "../oneColumnSection";
import Paragraphs from "../paragraphs";
import CtaButton from "../ctaButton";
import { useStyles } from "./upsell.styles";

interface IProps {
  blok: {
    actualPrice: number;
    pretendHigherPrice: number;
    ctaButton: ICtaButton[];
    declineLinkAndText: IContent;
  };
}

const Upsell = ({ blok }: IProps) => {
  const classes = useStyles();

  return (
    <OneColumnSection backgroundStyle="dark" widthStyle="text">
      <div className={classes.contentWrapper}>
        <Typography className={classes.priceText} variant="h2" component="h3">
          Only ${blok.actualPrice}, today!
        </Typography>
        <Typography variant="h3" component="h3" className={classes.pretendPriceText}>
          Normally:{" "}
          <span style={{textDecoration: "line-through"}}>
              ${blok.pretendHigherPrice}
            </span>
        </Typography>
        <div className={classes.buttonWrapper}>
          {blok.ctaButton?.length > 0 && <CtaButton ctaButton={blok.ctaButton}/>}
        </div>
        <Paragraphs paragraphs={blok.declineLinkAndText}/>
      </div>
    </OneColumnSection>
  );
};

export default Upsell;
