import React from "react";
import { useStyles } from "./bullet.styles";
import BulletIcon from "./bulletIcon.svg";
import Paragraphs from "../paragraphs";

interface IProps {
  text: IContent;
}

const Bullet = ({ text }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.iconWrapper}>
        <BulletIcon
          style={{
            width: "17px",
            height: "17px",
          }}
        />
      </div>
      <Paragraphs paragraphs={text} />
    </div>
  );
};

export default Bullet;
