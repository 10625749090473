import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import OneColumnSection from "../oneColumnSection";
import { useStyles } from "./moneyBackGuarantee.styles";
import guaranteeSeal from "./guaranteeSeal.png";

interface IPropsBlok extends IBlok {
  heading: string;
  text: string;
}

interface IProps {
  blok: IPropsBlok;
}

const MoneyBackGuarantee = ({ blok }: IProps) => {
  const classes = useStyles();

  return (
    <OneColumnSection
      id={blok.anchorTag}
      backgroundStyle="alternate"
      widthStyle="default"
    >
      <Container className={classes.container}>
        <img src={guaranteeSeal} className={classes.image} />
        <div>
          <Typography className={classes.heading} variant="h3" component="h3">
            {blok.heading}
          </Typography>
          <Typography className={classes.text} variant="body1" component="p">
            {blok.text}
          </Typography>
        </div>
      </Container>
    </OneColumnSection>
  );
};

export default MoneyBackGuarantee;
