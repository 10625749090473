import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    heading: {
      textAlign: "left",
      padding: "24px 0",
      maxWidth: "900px",

      [theme.breakpoints.up("lg")]: {
        padding: "0",
      },
    },
  });
});
