import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    important: {
      color: "#FF5454",
    },
    contentWrapper: {
      width: "100%",
      margin: "0 auto",
      maxWidth: "768px",
    },
    heading: {
      padding: "30px 0 26px",
    },
    subheading: {
      color: theme.palette.secondary.main,
      paddingBottom: "52px",
    },
  });
});
