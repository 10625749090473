import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import OneColumnSection from "../oneColumnSection";
import { useStyles } from "./cardGrid.styles";

interface IProps {
  id?: string;
  heading?: string;
  subheading?: string;
  children: JSX.Element | JSX.Element[];
}

const CardGrid = ({ id, heading, subheading, children }: IProps) => {
  const classes = useStyles();
  return (
    <OneColumnSection
      data-testid="cardGrid"
      id={id}
      backgroundStyle="alternate"
      widthStyle="default"
    >
      {heading && (
        <div className={classes.textWrapper}>
          <Typography variant="h3" component="h3">
            {heading}
          </Typography>
          {subheading && subheading?.length > 0 && (
            <Typography
              variant="h5"
              component="h4"
              style={{
                marginTop: 24,
                color: "#636463",
              }}
            >
              {subheading}
            </Typography>
          )}
        </div>
      )}
      <Container className={classes.grid}>{children}</Container>
    </OneColumnSection>
  );
};

export default CardGrid;
