import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./faqSection.styles";
import QuestionIcon from "./questionIcon.png";
import OpenIcon from "./openIcon.png";
import CloseIcon from "./closeIcon.png";

interface IFaq extends IBlok {
  _uid: string;
  question: string;
  answer: string;
}

interface IPropsBlok extends IBlok {
  heading: string;
  qAndAs: Array<IFaq>;
}

interface IProps {
  blok: IPropsBlok;
}

const Faq = ({ _uid, question, answer }: IFaq) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    <Box onClick={toggleOpen} className={classes.faq}>
      <div>
        <Typography variant="h4" component="h4">
          <div className={classes.questionIconWrapper}>
            <img src={QuestionIcon} />
          </div>
          <span className={classes.questionText}>{question}</span>
        </Typography>
        {isOpen && (
          <Typography
            variant="body1"
            component="p"
            className={classes.answerText}
          >
            {answer}
          </Typography>
        )}
      </div>
      <span className={classes.openStatus}>
        <img src={isOpen ? CloseIcon : OpenIcon} />
      </span>
    </Box>
  );
};

const FaqSection = ({ blok }: IProps) => {
  const classes = useStyles();
  return (
    <Box id={blok.anchorTag} className={classes.container}>
      <Typography variant="h3" component="h3" style={{ marginBottom: "80px" }}>
        {blok.heading}
      </Typography>
      <Container>
        {blok.qAndAs.map(({ question, answer, _uid }: IFaq) => (
          <Faq key={_uid} question={question} answer={answer} />
        ))}
      </Container>
    </Box>
  );
};

export default FaqSection;
