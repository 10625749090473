import { WindowLocation } from "@reach/router";
import { useEffect, useState } from "react";
import StoryblokClient, { StoryData } from "storyblok-js-client";
import config from "../../gatsby-config";

interface Constructable<T> {
  new (...args: any): T;
}

declare global {
  interface Window {
    StoryblokBridge: Constructable<StoryblokBridge>;
  }
}

const sbConfig = config.plugins.find(
  (item) =>
    typeof item !== "string" && item.resolve === "gatsby-source-storyblok"
);

const Storyblok = new StoryblokClient({
  accessToken:
    typeof sbConfig !== "string" ? sbConfig?.options.accessToken : "",
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export default function useStoryblok(
  originalStory: StoryData | null,
  location: WindowLocation<unknown>
) {
  let [story, setStory] = useState(originalStory);

  if (story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  function initEventListeners() {
    const { StoryblokBridge } = window;

    if (typeof StoryblokBridge !== "undefined") {
      const storyblokInstance = new StoryblokBridge();

      storyblokInstance.on(["published", "change"], () => {
        window.location.reload(true);
      });

      storyblokInstance.on(
        ["input"],
        (event: StoryblokEventPayload<any> | undefined) => {
          // if (event && story && event.story._uid === story._uid) {
          //   setStory(event.story);
          // }
          if (event) setStory(event.story);
        }
      );

      storyblokInstance.on(
        ["enterEditmode"],
        (event: StoryblokEventPayload<any> | undefined) => {
          Storyblok.get(`cdn/stories/${event ? event.storyId : ""}`, {
            version: "draft",
          })
            .then(({ data }) => {
              Storyblok.get(`cdn/stories/global`, {
                version: "draft",
              })
                .then((globalData) => {
                  const header = globalData.data.story.content.header;
                  const footer = globalData.data.story.content.footer;
                  const newStory = {
                    ...data.story,
                    content: { ...data.story.content, header, footer },
                  };

                  if (data.story) {
                    setStory(newStory);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      );
    }
  }

  function addBridge(callback: () => void) {
    const existingScript = document.getElementById("storyblokBridge");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`;
      script.id = "storyblokBridge";
      document.body.appendChild(script);
      script.onload = () => {
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    if (location.search.includes("_storyblok")) {
      addBridge(initEventListeners);
    }
  }, []);

  return story;
}
