import React from "react";
import SbEditable, { SbEditableContent } from "storyblok-react";
import ErrorBoundary from "./ErrorBoundary";

import About from "./about";
import AsFeaturedIn from "./asFeaturedIn";
import BasicHeader from "./basicHeader";
import BonusesSection from "./bonusesSection";
import BulletTextSection from "./bulletTextSection";
import CTASectionBreakBanner from "./ctaSectionBreakBanner";
import CountdownTimer from "./countdownTimer";
import FaqSection from "./faqSection";
import Footer from "./footer";
import FullWidthTextSection from "./fullWidthTextSection";
import GridOfInstructions from "./gridOfInstructions";
import Hero from "./hero";
import HeroWithVideo from "./heroWithVideo";
import ImageAndBullets from "./imageAndBullets";
import ImageAndBulletsCta from "./imageAndBulletsCta";
import ImageAndSocialLinks from "./imageAndSocialLinks";
import ImportantTextSection from "./importantTextSection";
import InfoGrid from "./infoGrid";
import LiveStreamHero from "./liveStreamHero";
import MediaAndLeadContent from "./mediaAndLeadContent";
import MoneyBackGuarantee from "./moneyBackGuarantee";
import NavHeader from "./navHeader";
import OneColumnCtaSection from "./oneColumnCta";
import PaymentOptions from "./paymentOptions";
import PopUpCta from "./popUpCta";
import ProductGrid from "./productGrid";
import SecureGuarantee from "./secureGuarantee";
import SingleColumnVideo from "./singleColumnVideo";
import SoundEmbed from "./soundEmbed";
import SpecialOfferBanner from "./specialOfferBanner";
import SpiffyCheckout from "./spiffyCheckout";
import Testimonials from "./testimonials";
import ThankYouBanner from "./thankYouBanner";
import TwoColumnCtaSection from "./twoColumnCta";
import TwoColumnVideo from "./twoColumnVideo";
import TwoColumnVideoCta from "./twoColumnVideoCta";
import Upsell from "./upsell";
import VideoHero from "./videoHero";
import CustomEmbed from "./customEmbed";

export interface IComponent {
  [index: string]: Function;
}

const Components: IComponent = {
  about: About,
  asFeaturedIn: AsFeaturedIn,
  basicFooter: Footer,
  basicHeader: BasicHeader,
  bonusesSection: BonusesSection,
  bulletTextSection: BulletTextSection,
  countdownTimer: CountdownTimer,
  ctaSectionBreakBanner: CTASectionBreakBanner,
  faq: FaqSection,
  fullWidthTextSection: FullWidthTextSection,
  gridOfInstructions: GridOfInstructions,
  hero: Hero,
  imageAndBullets: ImageAndBullets,
  imageAndBulletsCta: ImageAndBulletsCta,
  imageAndSocialLinks: ImageAndSocialLinks,
  importantTextSection: ImportantTextSection,
  infoGrid: InfoGrid,
  customEmbed: CustomEmbed,
  liveStreamHero: LiveStreamHero,
  liveStreamHeroWithVideo: HeroWithVideo,
  mediaAndLeadContent: MediaAndLeadContent,
  moneyBackGuarantee: MoneyBackGuarantee,
  navHeader: NavHeader,
  oneColumnCtaSection: OneColumnCtaSection,
  paymentOptions: PaymentOptions,
  popUpCta: PopUpCta,
  productGrid: ProductGrid,
  secureGuarantee: SecureGuarantee,
  singleColumnVideo: SingleColumnVideo,
  soundEmbed: SoundEmbed,
  specialOfferBanner: SpecialOfferBanner,
  spiffyForm: SpiffyCheckout,
  testimonials: Testimonials,
  testimonialsWithImages: Testimonials,
  thankYouBanner: ThankYouBanner,
  twoColumnCtaSection: TwoColumnCtaSection,
  twoColumnVideo: TwoColumnVideo,
  twoColumnVideoCta: TwoColumnVideoCta,
  upsell: Upsell,
  videoHero: VideoHero,
};

interface IProps {
  blok: SbEditableContent;
}

const DynamicComponent: React.FC<IProps> = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return (
      <SbEditable content={blok}>
        <ErrorBoundary component={blok.component}>
          <Component blok={blok} />
        </ErrorBoundary>
      </SbEditable>
    );
  }
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};

export default DynamicComponent;
