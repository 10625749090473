import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    btnWrapper: {
      marginBottom: "64px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "84px",
      },
    },
    bulletsWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 0",
      [theme.breakpoints.up("md")]: {
        padding: "64px 0",
      },
    },
  });
});
