import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      textAlign: "center",
      width: "100%",
      margin: "15px auto 0",
      maxWidth: "335px",
      paddingBottom: "60px",

      [theme.breakpoints.up("md")]: {
        maxWidth: "700px",
        paddingBottom: "80px",
      },

      [theme.breakpoints.up("lg")]: {
        maxWidth: "901px",
        paddingBottom: "60px",
      },
    },
    heading: {
      margin: "0 0 14px",
      [theme.breakpoints.up("md")]: {
        margin: "0 0 30px",
      },
      [theme.breakpoints.up("xl")]: {
        margin: "0 0 25px",
      },
    },
    preheading: {
      color: theme.palette.secondary.main,
    },
    subheading: {
      margin: "0 0 16px",
      [theme.breakpoints.up("md")]: {
        margin: "0 0 30px",
      },
      [theme.breakpoints.up("xl")]: {
        margin: "0 0 60px",
      },
    },
    logoSpacer: {
      marginTop: "50px",

      [theme.breakpoints.up("xl")]: {
        marginTop: "60px",
      },
    },
  });
});
