import React from "react";
import Typography from "@material-ui/core/Typography";
import Vimeo from "../vimeo";
import TwoColumnSection from "../twoColumnSection";

interface IPropsBlok extends IBlok {
  videoUrl: string;
  heading: string;
  subheading: string;
}

interface IProps {
  blok: IPropsBlok;
}

const TwoColumnVideo = ({ blok }: IProps) => {
  return (
    <TwoColumnSection
      id={blok.anchorTag}
      left={
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2" component="h3">
            {blok.heading}
          </Typography>
          <Typography variant="h4" component="h4">
            {blok.subheading}
          </Typography>
        </div>
      }
      right={<Vimeo videoUrl={blok.videoUrl} />}
    />
  );
};

export default TwoColumnVideo;
