import React from "react";
import Typography from "@material-ui/core/Typography";
import Paragraphs from "../paragraphs";
import { IContent } from "../paragraphs";
import { useStyles } from "./testimonialWidget.styles";

interface ITestimonialWidget {
  image?: IAsset;
  content: IContent;
  authorName: string;
  style?: Object;
}

const TestimonialWidget = ({
  image,
  content,
  authorName,
  style,
}: ITestimonialWidget) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={style}>
      {image && (
        <div
          className={classes.imageWrapper}
          style={{
            backgroundImage: `url(${image.filename})`,
          }}
        />
      )}
      <div className={classes.contentWrapper}>
        <Paragraphs paragraphs={content} />
        <Typography className={classes.authorName} variant="h4" component="h4">
          {authorName}
        </Typography>
      </div>
    </div>
  );
};

export default TestimonialWidget;
