import React, { useState, useEffect } from "react";

export interface IProps {
  style?: Object;
  videoUrl: string;
}

const Vimeo = ({ style, videoUrl }: IProps) => {
  const [video, setVideo] = useState("");
  const urlParams = `url=${videoUrl}&responsive=true&title=false&portrait=false&byline=false`;
  const vimeoApiUrl = `https://vimeo.com/api/oembed.json?${urlParams}`;

  useEffect(() => {
    fetch(vimeoApiUrl)
      .then((response) => response.json())
      .then((data) => setVideo(data.html));
  }, []);

  return (
    <>
      {video && (
        <div style={style} dangerouslySetInnerHTML={{ __html: video }} />
      )}
    </>
  );
};

export default Vimeo;
