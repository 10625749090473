import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#fafafa",
      padding: "48px 20px 0",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0",
      },
    },
    faq: {
      position: "relative",
      display: "flex",
      padding: "30px",
      backgroundColor: "#fff",
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      borderRadius: "4px",
      marginBottom: "20px",
    },
    questionIconWrapper: {
      marginRight: "30px",
      width: "64px",
      height: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(75, 153, 156, 0.08)",
      borderRadius: "70px",
      [theme.breakpoints.up("md")]: {
        display: "inline-flex",
      },
    },
    questionText: {
      display: "inline-block",
      padding: "20px 0",
      maxWidth: "768px",
      [theme.breakpoints.up("md")]: {
        padding: "0",
      },
    },
    answerText: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "94px",
      },
    },
    openStatus: {
      position: "absolute",
      right: "30px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "auto",
        position: "static",
        paddingLeft: "30px",
      },
    },
  });
});
