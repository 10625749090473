import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "../link";
import { useStyles } from "./paragraphs.styles";

interface ILinkAttr {
  href: string;
  linktype: string;
  target: string;
}

interface IMark {
  type: string;
  attrs?: unknown;
}

interface IParagraph {
  type: string;
  text: string;
  marks?: IMark[];
}

interface IParagraphs {
  paragraphs: IContent | string;
}

const Paragraphs = ({ paragraphs }: IParagraphs) => {
  const classes = useStyles();

  if (typeof paragraphs === "string") {
    return (
      <Typography variant="body1" component="p">
        {paragraphs}
      </Typography>
    );
  }

  const foo = (paragraphs) => {
    return (paragraphs.content as IContent[]).map((paragraph) => {
      switch (paragraph.type) {
        case "paragraph":
          const content = (paragraph?.content as IParagraph[])?.map(
            ({ text, marks }) => {
              if (marks !== undefined) {
                return marks?.map((mark) => {
                  switch (mark.type) {
                    case "bold":
                      return (
                        <Typography variant="body2" component="strong">
                          {text}
                        </Typography>
                      );
                    case "italic":
                      return (
                        <Typography
                          variant="body1"
                          component="span"
                          style={{
                            fontStyle: "italic",
                          }}
                        >
                          {text}
                        </Typography>
                      );
                    case "link":
                      const attrs = mark.attrs as ILinkAttr;
                      return (
                        <Link
                          url={attrs.href}
                          anchor={attrs.anchor}
                          linkType={attrs.linktype}
                          target={attrs.target}
                        >
                          {text}
                        </Link>
                      );
                  }
                });
              } else {
                return text;
              }
            }
          );
          return (
            <Typography
              variant="body1"
              component="p"
              style={{
                marginBottom: "20px",
              }}
            >
              {content}
            </Typography>
          );
        case "ordered_list":
          return (
            <ol>
              {(paragraph.content as IContent[]).map((li) => {
                const liContent = (li.content as IContent[])[0];
                return (
                  <li>
                    {(liContent.content as []).map(
                      ({ text, marks }: IParagraph) => {
                        if (marks !== undefined) {
                          return marks?.map((mark) => {
                            if (mark.type === "link") {
                              const attrs = mark.attrs as ILinkAttr;
                              return (
                                <Link
                                  url={attrs.href}
                                  linkType={attrs.linktype}
                                  target={attrs.target}
                                >
                                  {text}
                                </Link>
                              );
                            }
                          });
                        }
                        return text;
                      }
                    )}
                  </li>
                );
              })}
            </ol>
          );
        case "bullet_list":
          return (
            <ul className={classes.bulletList}>
              {(paragraph.content as IContent[]).map((li) => {
                const liContent = (li.content as IContent[])[0];
                // const liText = (liContent.content[0] as IParagraph).text;
                const liText = liContent.content.map((content) => {
                  if (content?.marks && content.marks[0].type == "bold") {
                    return (
                      <Typography variant="body2" component="strong">
                        {content.text}
                      </Typography>
                    );
                  } else {
                    return content.text;
                  }
                });
                return <li>{liText}</li>;
              })}
            </ul>
          );
        case "heading":
          return (
            <Typography variant="h5" component="h5">
              {(paragraph.content as IParagraph[]).map(({ text }) => text)}
            </Typography>
          );
      }
    });
  };

  return <>{foo(paragraphs)}</>;
};

export default Paragraphs;
