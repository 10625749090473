import React from "react";
import Card from "../card";
import Typography from "@material-ui/core/Typography";
import infoIcon from "./infoIcon.png";
import { useStyles } from "./infoCard.styles";
import Paragraphs from "../paragraphs";

interface IProps {
  icon?: IAsset;
  heading: string;
  content: IContent;
}

const InfoCard = ({ icon, heading, content }: IProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.iconWrapper}>
        <img
          className={classes.icon}
          src={icon ? icon.filename : infoIcon}
          style={{
            opacity: icon ? "0.8" : "1",
          }}
        />
      </div>
      <Typography variant="h4" component="h4" className={classes.heading}>
        {heading}
      </Typography>
      <Paragraphs paragraphs={content} />
      <div className={classes.gutter} />
    </Card>
  );
};

export default InfoCard;
