import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Logo from "../logo";
import { useStyles } from "./thankYouBanner.styles";

interface IPropsBlok extends IBlok {
  logo: IAsset;
  heading: string;
  subHeading: string;
}

interface IProps {
  blok: IPropsBlok;
}

const ThankYouBanner = ({ blok }: IProps) => {
  const classes = useStyles();
  return (
    <Container id={blok.anchorTag} className={classes.container}>
      {blok?.logo?.filename ? <Logo filename={blok.logo.filename} /> : <></>}
      <div className={classes.contentWrapper}>
        <div>
          <Typography variant="h1" component="h1" className={classes.heading}>
            {blok?.heading}
          </Typography>
          <Typography
            variant="h3"
            component="h3"
            className={classes.subheading}
          >
            {blok?.subHeading}
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default ThankYouBanner;
