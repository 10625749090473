import merge from "deepmerge";
import { createTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useResponsiveTheme = () => {
  // if no theme env variable exists load the default theme file
  if (!process.env.GATSBY_THEME) {
    const themeConfig = require(`../material-ui-theme/default.json`);
    const theme = createTheme(themeConfig);

    return theme;
  }
  const type = process.env.GATSBY_THEME;
  const baseThemeConfig = require(`../material-ui-theme/${type}/mobile.json`);
  const tabletThemeConfig = require(`../material-ui-theme/${type}/tablet.json`);
  const desktopThemeConfig = require(`../material-ui-theme/${type}/desktop.json`);

  // generate a base theme using the mobile theme file.
  const theme = createTheme(baseThemeConfig);

  const tablet = useMediaQuery(theme.breakpoints.up("md"));
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  let responsiveTheme = theme;

  // if the device is larger than a phone, fold in the additional theme's
  // styles, overriding the current themes styles where applicable.
  if (tablet) {
    responsiveTheme = merge(theme, tabletThemeConfig);
  }
  if (desktop) {
    responsiveTheme = merge(responsiveTheme, desktopThemeConfig);
  }
  return responsiveTheme;
};

export default useResponsiveTheme;
