import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "../card";
import Paragraphs from "../paragraphs";
import OneColumnSection from "../oneColumnSection";
import { useStyles } from "./importantTextSection.styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ImportantTextSection = ({ blok }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  return (
    <OneColumnSection
      id={blok.anchorTag}
      backgroundStyle="alternate"
      widthStyle="text"
    >
      <div className={classes.contentWrapper}>
        <Card>
          <Typography
            data-testid="important"
            className={classes.important}
            variant="h4"
            component="h4"
          >
            {mobile
              ? "• • Important • •"
              : "• • • • • • • • Important • • • • • • • • •"}
          </Typography>
        </Card>
        <Typography className={classes.heading} variant="h3" component="h3">
          {blok.heading}
        </Typography>
        <Typography className={classes.subheading} variant="h4" component="h4">
          {blok.subheading}
        </Typography>
        <Paragraphs paragraphs={blok.content} />
      </div>
    </OneColumnSection>
  );
};

export default ImportantTextSection;
