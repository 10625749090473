import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            textAlign: "center",
            padding: "40px 20px 60px 20px",
            [theme.breakpoints.up("md")]: {
                padding: "60px 34px 80px 34px"
            },
            [theme.breakpoints.up("lg")]: {
                padding: "100px 0 120px 0"
            }
        },
        heading: {
            marginBottom: "24px",
            [theme.breakpoints.up("md")]: {
                marginBottom: "40px"
            },
            [theme.breakpoints.up("lg")]: {
                marginBottom: "46px"
            }
        }
    });
});
