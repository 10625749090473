import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      padding: "24px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0",
      },
    },
    contentWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      margin: "0 auto",
    },
    bgDefault: {
      backgroundColor: theme.palette.background.default,
    },
    bgAlt: {
      backgroundColor: "#fafafa",
    },
    bgDark: {
      backgroundColor: theme.palette.primary.main,
    },
  });
});
