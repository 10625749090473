import React from 'react';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { KeyboardArrowDown } from "@material-ui/icons";
import ArrowIcon from './assets/arrow-right-up-alt.svg'

export interface MenuData {
  name: string,
  link: string,
  icon?: JSX.Element,
  target?: string,
}

export interface DesktopData {
  name: string,
  link: string,
  icon?: JSX.Element,
  target?: string,
  menu?: MenuData[]
}

export const getDesktopNavData = (classNames: ClassNameMap) => [
  {
    name: 'Products',
    link: 'https://www.holdenqigong.com/all-products',
    icon: <KeyboardArrowDown/>,
    menu: [
      {
        name: 'All Products',
        link: 'https://www.holdenqigong.com/all-products/',
      },
      {
        name: 'Online Classes',
        link: 'https://lp.holdenqigong.com/video-class-subscription',
        icon: <ArrowIcon className={classNames.arrowIcon} />,
        target: '_blank',
      },
      {
        name: '30-Day Challenge',
        link: 'https://7mins.holdenqigong.com/video-practice',
        icon: <ArrowIcon className={classNames.arrowIcon} />,
        target: '_blank',
      },
      {
        name: 'Guided Healing Meditations',
        link: 'https://pages.holdenqigong.com/guided-healing-meditations?cfid=14',
        icon: <ArrowIcon className={classNames.arrowIcon} />,
        target: '_blank',
      },
      {
        name: 'Healing Series',
        link: 'https://www.holdenqigong.com/product-category/healing-series/',
      },
      {
        name: 'Learning Series',
        link: 'https://www.holdenqigong.com/product-category/learning-series/',
      },
      {
        name: 'Legacy Series',
        link: 'https://www.holdenqigong.com/product-category/legacy/',
      },
      {
        name: 'Previous Workshops',
        link: 'https://www.holdenqigong.com/product-category/previous-workshops',
      },
      {
        name: 'Acupressure',
        link: 'https://lp.holdenqigong.com/acupressure-online-course',
      },
      {
        name: 'Buddha Palm',
        link: 'https://secure.holdenqigong.com/buddha-palm-course/',
      },
      {
        name: 'Breathwork',
        link: 'https://secure.holdenqigong.com/breathwork-online-course/?_ga=2.113325444.289932432.1671461575-1718219089.1671461575',
      },
      {
        name: 'Five Animal Frolics',
        link: 'https://secure.holdenqigong.com/five-animal-frolics-online-course/?_ga=2.189487400.289932432.1671461575-1718219089.1671461575',
      },
      {
        name: 'Five Elements',
        link: 'https://www.holdenqigong.com/five-elements-program/',
      },
      {
        name: 'Healing Sounds',
        link: 'https://secure.holdenqigong.com/healing-sounds/',
      },
      {
        name: 'Iron Shirt',
        link: 'https://secure.holdenqigong.com/iron-shirt-qi-gong-course/',
      },
      {
        name: 'Tao Yin',
        link: 'https://secure.holdenqigong.com/tao-yin-online-course/',
      },
      {
        name: 'Three Treasures',
        link: 'https://www.holdenqigong.com/three-treasures-program/',
      },
    ],
  },
  {
    name: 'Events',
    link: 'https://www.holdenqigong.com/events/',
    icon: <KeyboardArrowDown/>,
    menu: [
      {
        name: 'All Upcoming Events',
        link: 'https://www.holdenqigong.com/events/',
      },
      {
        name: 'Online Classes',
        link: 'https://lp.holdenqigong.com/video-class-subscription-homepage',
      },
      {
        name: 'Online Courses',
        link: 'https://www.holdenqigong.com/events/online-courses/',
      },
      {
        name: 'QiGong Retreats',
        link: 'https://www.holdenqigong.com/event_type/qi-gong-retreats',
      },
      {
        name: 'Workshops',
        link: 'https://www.holdenqigong.com/events/workshops/',
      },
    ]
  },
  {
    name: 'Teacher Training',
    link: 'https://www.holdenqigong.com/qigong-teacher-training-online-program/',
    icon: <KeyboardArrowDown/>,
    target: '_blank',
    menu: [
      {
        name: 'Tier 1 – Online',
        link: 'https://www.holdenqigong.com/qigong-teacher-training-online-program/',
      },
      {
        name: 'Tier 2 – Advanced',
        link: 'https://www.holdenqigong.com/qigong-teacher-training-tier-2/',
      }
    ]
  },
  {
    name: 'Resources',
    link: 'https://www.holdenqigong.com/qi-gong-resources/',
    icon: <KeyboardArrowDown/>,
    menu: [
      {
        name: 'Articles',
        link: 'https://www.holdenqigong.com/qi-gong-resources/',
      },
      {
        name: 'Support Center',
        link: 'https://support.holdenqigong.com/',
      },
      {
        name: 'Teacher Directory',
        link: 'https://www.holdenqigong.com/teacher-directory/',
      },
      {
        name: 'YouTube Channel',
        link: 'https://www.youtube.com/channel/UCywnihPARyOZxlYpO8wmovA',
      },
      {
        name: 'Immunity Kit',
        link: 'https://secure.holdenqigong.com/immunity-emergency-kit/',
      }
    ]
  },
  {
    name: 'Student Login',
    link: 'https://students.holdenqigong.com/users/login/',
    icon: <ArrowIcon className={classNames.arrowIcon}/>,
    target: '_blank',
  },
] as DesktopData[]

