import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: "100%",
      textAlign: "center",
      padding: "0 24px 24px",
      [theme.breakpoints.up("md")]: {
        padding: "0 34px 36px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "0 0 75px",
      },
    },
    contentWrapper: {
      padding: "64px 20px 0",
      [theme.breakpoints.up("lg")]: {
        padding: "64px 0 0",
      },
    },
    heading: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("xs")]: {
        fontSize: 34,
        lineHeight: "44.2px",
      },
    },
    subheading: {
      padding: "36px 0 0",
      [theme.breakpoints.up("lg")]: {
        padding: "75px 0 0",
      },
    },
  });
});
