import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    link: {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.secondary.dark,
      },
    },
  });
});
