import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: "100%",
      height: "100vh",
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    btn: {
      color: "#fff",
      position: "absolute",
      width: 32,
      height: 60,
      top: 24,
      right: 32,
    },
    preheading: {
      color: theme.palette.secondary.main,
      fontSize: "20px",
      marginBottom: "30px",
    },
    text: {
      color: "#fff",
      width: "100%",
      maxWidth: "900px",
      margin: "50px 0 80px",
    },
  });
});
