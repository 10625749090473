import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#fafafa",
      padding: "24px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0",
      },
    },
    scrollbox: {
      width: "100vw",
      padding: "3rem 10%",
      overflowX: "scroll",
      display: "flex",
      flexWrap: "nowrap",
      gap: "20px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    scrollImageWrapper: {
      transform: "translateX(0)",
      minWidth: "calc(25vw - 10px)",
      width: "calc(25vw - 10px)",
      height: "104px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      borderRadius: "4px",
      filter: "grayscale(1)",
    },
    scrollControlsWrapper: {
      position: "absolute",
      height: "100%",
      left: 0,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    scrollButtonWrapper: {
      height: "100%",
      width: "158px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 999,
    },
    scrollButton: {
      backgroundColor: "#fff",
      borderRadius: "100%",
      width: "64px",
      height: "64px",
      border: `solid 3px${theme.palette.secondary.main}`,
    },
    grid: {
      width: "100%",
      padding: "0",
      margin: "80px 0 0",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "16px",
      [theme.breakpoints.up("md")]: {
        gap: "20px",
      },
    },
    imageWrapper: {
      width: "100%",
      height: "88px",
      [theme.breakpoints.up("md")]: {
        height: "104px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "calc(16.66667% - 20px)",
        height: "134px",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      borderRadius: "4px",
      filter: "grayscale(1)",
    },
    image: {
      maxWidth: "128px",
    },
  });
});
