import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useStyles } from "./singleColumnVideo.styles";
import Vimeo from "../vimeo";
import CtaButton from "../ctaButton";

interface IPropsBlok extends IBlok {
  heading: string;
  videoUrl: string;
  ctaButton: Array<ICtaButton>;
}

interface IProps {
  blok: IPropsBlok;
}

const SingleColumnVideo = ({ blok }: IProps) => {
  const classes = useStyles();

  return (
    <Container id={blok.anchorTag} className={classes.container}>
      <Typography variant="h3" component="h3">
        {blok.heading}
      </Typography>
      <div style={{ maxWidth: "768px", margin: "0 auto" }}>
        <div className={classes.videoWrapper}>
          <Vimeo videoUrl={blok.videoUrl} />
        </div>
        {blok.ctaButton?.length > 0 && <CtaButton ctaButton={blok.ctaButton} />}
      </div>
    </Container>
  );
};

export default SingleColumnVideo;
