import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "40px 20px",
      [theme.breakpoints.up("sm")]: {
        padding: "60px 34px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "100vh",
        justifyContent: "center",
      },
    },
    title: {
      maxWidth: "768px",
      margin: "0 0 26px",
    },
    subtitle: {
      color: theme.palette.secondary.main,
    },
    btnContainer: {
      width: "100%",
      maxWidth: "600px",
    },
    notice: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#FF545414",
      borderRadius: "60px",
      margin: "0 0 4vh",
      padding: "15px 30px",
    },
    icon: {
      width: "13px",
      height: "13px",
      borderRadius: "100%",
      backgroundColor: "#FF5454",
    },
    iconWrapper: {
      padding: "14px",
      borderRadius: "100%",
      backgroundColor: "rgba(255, 84, 84, 0.14)",
      marginRight: "16px",
    },
    noticeText: {
      color: "#FF5454",
      textTransform: "uppercase",
    },
    text: {
      margin: "4vh 0 10vh",
    },
  });
});
