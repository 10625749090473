import React from "react";
import Typography from "@material-ui/core/Typography";
import PaperformEmbed from "../paperformEmbed";
import TwoColumnSection from "../twoColumnSection";
import { useStyles } from "./twoColumnCta.styles";

const TwoColumnCtaSection = ({ blok }: ICtaSection) => {
  const classes = useStyles();
  return (
    <TwoColumnSection
      id={blok.anchorTag}
      left={
        <Typography className={classes.heading} variant="h3" component="h3">
          {blok.mainHeading}
        </Typography>
      }
      right={<PaperformEmbed embedCode={blok.paperformEmbed} />}
    />
  );
};

export default TwoColumnCtaSection;
