import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: "100%",
      padding: "0 24px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "0 34px 20px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "0 0 20px",
      },
    },
  });
});
