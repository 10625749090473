import React from "react";
import Typography from "@material-ui/core/Typography";
import Paragraphs from "../paragraphs";
import { IContent } from "../paragraphs";
import OneColumnSection from "../oneColumnSection";

interface IPropsBlok extends IBlok {
  heading: string;
  paragraphs: IContent;
}

interface IProps {
  blok: IPropsBlok;
}

const FullWidthTextSection = ({ blok }: IProps) => {
  return (
    <OneColumnSection id={blok.anchorTag} widthStyle="text">
      <Typography
        variant="h3"
        component="h3"
        style={{
          paddingBottom: "26px",
        }}
      >
        {blok?.heading}
      </Typography>
      <Paragraphs paragraphs={blok?.paragraphs} />
    </OneColumnSection>
  );
};

export default FullWidthTextSection;
