import React from "react";
import Typography from "@material-ui/core/Typography";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import Img from "gatsby-image";
import Paragraphs from "../paragraphs";
import SocialMediaLinks from "../socialMediaLinks";
import TwoColumnSection from "../twoColumnSection";
import { IContent } from "../paragraphs";

interface IPropsBlok extends IBlok {
  image: IAsset;
  heading: string;
  paragraphs: IContent;
  socialMediaLinks: ILink[];
}

interface IProps {
  blok: IPropsBlok;
}

const ImageAndSocialLinks = ({ blok }: IProps) => {
  const fluidProps = getFluidGatsbyImage(blok.image.filename, {
    maxWidth: 900,
  });

  return (
    <TwoColumnSection
      toggleStackOrder
      id={blok.anchorTag}
      left={
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Img
            alt={blok.image.alt}
            fluid={fluidProps}
            style={{
              maxWidth: 900,
            }}
          />
        </div>
      }
      right={
        <div
          style={{
            maxWidth: 900,
            margin: "0 auto 84px",
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            style={{
              padding: "0 0 24px",
              textAlign: "left",
            }}
          >
            {blok.heading}
          </Typography>
          <Paragraphs paragraphs={blok?.paragraphs} />
          <SocialMediaLinks blok={blok.socialMediaLinks[0]} />
        </div>
      }
    />
  );
};

export default ImageAndSocialLinks;
