import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      margin: "auto 0",
      backgroundColor: "#fff",
      borderRadius: "0.3rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      textAlign: "center",
      padding: "30px 20px 42px",

      [theme.breakpoints.up("sm")]: {
        padding: "46px 40px",
      },
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
        textAlign: "left",
      },
    },
    heading: {
      color: theme.palette.text.primary,
      textAlign: "center",
      margin: "0 auto",
      width: "100%",
      maxWidth: "494px",
      paddingBottom: "20px",
      [theme.breakpoints.up("md")]: {
        paddingBottom: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "671px",
        paddingBottom: "34px",
        textAlign: "left",
        margin: "0",
      },
    },
    text: {
      color: "#242937",
      maxWidth: "834px",
    },
    image: {
      width: "100%",
      maxWidth: 121,
      marginBottom: "24px",
      [theme.breakpoints.up("md")]: {
        maxWidth: 160,
        marginBottom: "30px",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "312px",
        margin: "0",
      },
    },
  });
});
