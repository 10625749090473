import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    contentWrapper: {
      textAlign: "center",
      width: "100%",
      margin: "0 auto",
      maxWidth: "900px",
    },
    heading: {
      color: "#fff",
      padding: "0 0 26px",
    },
    subheading: {
      color: theme.palette.secondary.main,
      paddingBottom: "54px",
    },
    disclaimerText: {
      color: "#fff",
      marginTop: "40px",
      paddingBottom: "20px",
      fontStyle: "italic",
    },
  });
});
