import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    card: {
      width: "100%",
      maxWidth: "900px",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("lg")]: {
        textAlign: "center",
        width: "32%",
      },
    },
    icon: {
      width: "21.55px",
      [theme.breakpoints.up("lg")]: {
        width: "43.1px",
      },
    },
    iconWrapper: {
      width: "80px",
      height: "80px",
      margin: "30px auto 24px",
      backgroundColor: "rgba(234, 181, 77, 0.16)",
      borderRadius: "170px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        display: "inline-flex",
        margin: "40px 20px 30px 0",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        width: "160px",
        height: "160px",
        margin: "30px auto 40px",
      },
    },

    heading: {
      marginBottom: "20px",
      display: "inline-block",
      verticalAlign: "center",
      [theme.breakpoints.up("sm")]: {
        position: "relative",
        top: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        top: "0",
      },
    },

    gutter: {
      height: "10px",
    },
  });
});
