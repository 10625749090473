import React from "react";
import { useStyles } from "./spiffyCheckout.styles";

interface IProps {
  blok: {
    embed: string;
  };
}

const SpiffyCheckout = ({ blok }: IProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      dangerouslySetInnerHTML={{ __html: blok.embed }}
    />
  );
};

export default SpiffyCheckout;
