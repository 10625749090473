import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      fontSize: '12px',
      lineHeight: '1em',
      backgroundColor: '#2a3048',
      position: 'relative',
      top: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      width: '100%',
      boxShadow: '0 1px 0 rgb(0 0 0 / 10%)',
      zIndex: 99999,

      '&.fixed': {
        position: 'fixed',
      },

      '& ul': {
        margin: 0,
        fontSize: '100%',
        verticalAlign: 'baseline',
        listStyle: 'none',
        transition: 'all .2s ease-in-out',
      },

      '& svg': {
        fill: '#fff',
      },

      '& li a': {
        color: '#fff',
        textDecoration: 'none',
        fontWeight: '500'
      },

      '& a:hover': {
        backgroundColor: 'rgba(0,0,0,.03)',
        opacity: .7,
      },

      '& li': {
        lineHeight: '1em',
      },

      '&.scrolled ul > li>a:first-child': {
        paddingBottom: '20px!important',
      },
    },
    container: {
      position: 'relative',

      '&:after': {
        clear: 'both',
        display: 'block',
        content: '""',
      },
    },
    logoContainer: {
      position: 'absolute',
      height: '100%',
      // width: '100%',
      transition: 'all 0.4s ease-in-out',
      zIndex: 0,
      '& a:hover': {
        opacity: 1,
      },
    },
    logoHelper: {
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle',
      width: 0,
    },
    logo: {
      height: '122px',
      transition: 'all 0.4s ease-in-out',
      marginBottom: 0,
      maxHeight: '54%',
      display: 'inline-block',
      float: 'none',
      verticalAlign: 'middle',

      '& > div': {
        height: '100%!important',
      },

      [theme.breakpoints.up(981)]: {
        maxHeight: '76%',
      },

      [theme.breakpoints.down(981)]: {
        maxHeight: '50%',
      }
    },
    topMenuNav: {
      float: 'left',

      '& a': {
        display: 'block',
      },

      '& li a': {
        letterSpacing: 'normal',
        textTransform: 'uppercase',
        color: '#fff',
      },

      '& li ul': {
        position: 'absolute',
        padding: '20px 0',
        zIndex: 9999,
        width: '240px',
        background: '#fff',
        visibility: 'hidden',
        opacity: 0,
        borderTop: '3px solid #edb059',
        boxShadow: '0 2px 5px rgb(0 0 0 / 10%)',
        textAlign: 'left',
        backgroundColor: '#2a3048',
      },

      '& li li': {
        padding: '0 20px',
        margin: 0,
        position: 'relative',
        lineHeight: '2em',
      },

      '& li li a': {
        padding: '6px 20px',
        width: '200px',
      },

      '& li:hover>ul': {
        opacity: 1,
        visibility: 'visible',
      },

      '& > ul > li': {
        paddingRight: '22px',
      },

      '& > ul > li>a:first-child': {
        paddingBottom: '33px',
        display: 'flex',
        alignItems: 'center',

        '& svg': {
          height: '18px',
        }
      },
    },
    etCartInfo: {
      float: 'left',

      '&:hover': {
        opacity: '1!important',
      },

      [theme.breakpoints.down(999)]: {
        marginTop: '5px',
      },
    },
    etTopNavigation: {
      padding: '36px 0 0 193px',
      float: 'right',
      zIndex: 9999,
      transition: 'all 0.4s ease-in-out',

      '&.scrolled': {
        paddingTop: '22px',
      },

      '& li a': {
        transition: 'all .4s ease-in-out',
      },

      [theme.breakpoints.down(980)]: {
        paddingTop: '24px',
      }
    },
    topMenu: {
      '& li': {
        display: 'inline-block',
        fontSize: '14px',
      },

      [theme.breakpoints.down(999)]: {
        display: 'none',
      }
    },
    arrowIcon: {
      maxHeight: '14px',
      width: '14px',
      marginLeft: '5px',
    },
    menuItem: {
      '& svg': {
        position: 'relative',
        bottom: '-2px',
      }
    },
    cartIcon: {
      width: '16px',
      height: '16px',
      marginRight: '10px',
    },
    menuIcon: {
      width: '32px',
      height: '32px',
      fill: '#edb059!important',
      marginBottom: '21px',
      cursor: 'pointer',
    },
    etMobileNavMenu: {
      float: 'right',
      display: 'none',

      [theme.breakpoints.down(999)]: {
        display: 'block',
      }
    },
    etMobileMenu: {
      backgroundColor: '#2a3048',
      maxHeight: 0,
      paddingTop: 0,
      paddingBottom: 0,

      overflow: 'auto',

      position: 'absolute',
      left: 0,
      padding: '5%',
      background: '#fff',
      width: '100%',
      visibility: 'visible',
      opacity: 1,
      zIndex: 9999,
      boxShadow: '0 2px 5px rgb(0 0 0 / 10%)',
      borderTop: '0 solid #edb059',

      '& li': {
        lineHeight: '29px',
        fontSize: '18px',
      },

      '& li a': {
        padding: '3px 5%',
        borderBottom: '1px solid rgba(0,0,0,.03)',
        fontWeight: 700,
        fontSize: '14px',
        transition: 'all .2s ease-in-out',
      }
    },
    mobileNav: {
      lineHeight: 'unset',
    },
    mobMenuOpen: {
      display: 'block',
    }
  })
});