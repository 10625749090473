import React, { useEffect } from "react";
// import { useLocation } from "@reach/router";
import smoothscroll from "smoothscroll-polyfill";

interface IProps {
  className?: string;
  link: ICtaButton;
}

const CtaLink = ({ className, link }: IProps) => {
  // Needed for location.pathname test below, but the code is currently commented out.
  // const location = useLocation();
  const { url, anchor, linktype, cached_url } = link.link;

  const styles = {
    textDecoration: "none",
    color: "#fff",
    display: "block",
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const scrollToAnchor = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
/*
        if (
          anchor &&
          linktype === "story" &&
          `/${cached_url}` === location.pathname
        ) {
*/
    if (anchor &&
        linktype === "story") {
      e.preventDefault();
      const element = document.getElementById(anchor);
      if (element) {
        window.scrollTo({top: element.offsetTop, behavior: "smooth"});
      }
    }
  };

  if (linktype === "story") {
    return (
      <a
        className={className ? className : ""}
        data-testid="gatsby-link"
        style={styles}
        onClick={scrollToAnchor}
        href={`/${cached_url}`}
        target={link.window}
      >
        {link.text}
      </a>
    );
  } else {
    return (
      <a
        className={className ? className : ""}
        data-testid="anchor-link"
        style={styles}
        href={linktype === "email" ? `mailto:${url}` : url}
        target={link.window}
      >
        {link.text}
      </a>
    );
  }
};

export default CtaLink;
