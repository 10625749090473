import React from "react";
import classNames from "classnames";
import Card from "../card";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./paymentCard.styles";
import CtaButton from "../ctaButton";

interface IProps {
  blok: {
    preheading: string;
    heading: string;
    subheading: string;
    price: number;
    frequency: string;
    ctaButton: Array<ICtaButton>;
  };
  variant: 'default' | 'alt';
}

const PaymentCard = ({ blok, variant }: IProps) => {
  const useAltStyles = variant === 'alt';

  const classes = useStyles();
  const { linktype, url, cached_url } = blok.ctaButton[0].link;

  return (
    <Card className={useAltStyles ? classes.cardAlt : classes.card}>
      <a href={linktype === "story" ? cached_url : url} target="_self" className={classes.linkWrapper}>
        <Typography
          variant="h5"
          component="h4"
          className={classes.heading}
        >
          {blok.preheading}
        </Typography>
        <Typography variant="h3" component="h3">
          {blok.heading}
        </Typography>
        <Typography variant="body1" component="p" className={classes.heading}>
          {blok.subheading}
        </Typography>
        <Typography
          variant="h1"
          component="p"
          className={classes.price}
        >
          {`$${blok.price}${blok.frequency ? "/" + blok.frequency : ''}`}
        </Typography>
        <CtaButton variant={useAltStyles ? 'alt' : 'main'} ctaButton={blok.ctaButton} />
      </a>
    </Card>
  );
};

export default PaymentCard;
