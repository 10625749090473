import React from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "./card.styles";

interface IProps {
  children: JSX.Element | Array<JSX.Element | undefined | "">;
  className?: string;
}

const Card = ({ className, children }: IProps) => {
  const { card } = useStyles();
  return (
    <Box data-testid="card" className={`${card} ${className}`}>
      {children}
    </Box>
  );
};

export default Card;
