import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    image: {
      border: `solid 6px ${theme.palette.secondary.main}`,
      boxSizing: "border-box",
      borderRadius: "30px",
      marginBottom: "84px",
      [theme.breakpoints.up("lg")]: {
        marginBottom: "0px",
      },
    },
    bulletsWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "84px",
      [theme.breakpoints.up("lg")]: {
        marginBottom: "0px",
      },
    },
  });
});
