import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import PaperformEmbed from "../paperformEmbed";
import { useStyles } from "./oneColumnCta.styles";

const OneColumnCtaSection = ({ blok }: ICtaSection) => {
  const classes = useStyles();

  return (
    <Container id={blok.anchorTag} className={classes.section}>
      <Box>
        <Typography variant="h3" component="h3" className={classes.heading}>
          {blok.mainHeading}
        </Typography>
        <div className={classes.formWrapper}>
          <div
            data-testid="paperform-container"
            className={classes.formWrapperInner}
          >
            <PaperformEmbed embedCode={blok.paperformEmbed} />
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default OneColumnCtaSection;
