import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      width: "100%",
      boxShadow: "0px 9px 34px rgba(36, 41, 55, 0.08)",
      borderRadius: "10px",
      padding: "84px 30px 30px",
        margin: "0 0 86px",

      "&:nth-child(odd)": {
        flexDirection: "row-reverse",
      },

      [theme.breakpoints.up("lg")]: {
        margin: "0 0 24px",
        padding: "50px",
      },
    },
    imageWrapper: {
      maxWidth: 126,
      width: "100%",
      height: 126,
      position: "absolute",
      top: -63,
      left: "calc(50% - 63px)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: "50%",
      marginRight: 60,
      [theme.breakpoints.up("lg")]: {
        maxWidth: 260,
        width: "100%",
        height: 260,
        position: "initial",
      },
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "900px",
      textAlign: "center",
      [theme.breakpoints.up("lg")]: {
        textAlign: "left",
      },
    },
    authorName: {
      color: "#4B999C",
    },
  });
});
