import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      display: "block",
      textAlign: "center",
      textDecoration: "none",
      boxShadow: "0 2px 6px -2px rgb(0 0 0 / 50%)",
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
      padding: "15px",
      fontSize: "18px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: "0 4px 14px -6px rgb(0 0 0 / 80%)",
      },
    },
    altButton: {
      display: "block",
      textAlign: "center",
      textDecoration: "none",
      backgroundColor: "#fff",
      boxShadow: "0 2px 6px -2px rgb(0 0 0 / 50%)",
      width: "100%",
      color: `${theme.palette.primary.main}!important`,
      fontSize: "18px",
      textTransform: "none",
      padding: "15px",
      "&:hover": {
        backgroundColor: "#fff",
        boxShadow: "0 4px 14px -6px rgb(0 0 0 / 80%)",
      },
    },
  });
});
