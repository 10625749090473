import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#fafafa",
      padding: "24px 20px",
      [theme.breakpoints.up("md")]: {
        padding: "36px 34px",
      },
      [theme.breakpoints.up("xl")]: {
        padding: "75px 0",
      },
    },
    textWrapper: {
      maxWidth: "768px",
      margin: "0 auto",
      textAlign: "center",
    },
    grid: {
      padding: "0",
      margin: "50px 0 0",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "24px",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.up("xl")]: {
        margin: "80px 0 0",
      },
    },
  });
});
