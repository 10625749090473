import React from "react";
import useScriptSrc from "../../hooks/useScriptSrc";

interface IPaperformEmbed {
  embedCode: string;
}

const PaperformEmbed = ({ embedCode }: IPaperformEmbed) => {
  const srcUrlIndex = embedCode?.indexOf("https") || -1;
  const paperformStr = 'data-paperform-id="';
  const formIdIndex = embedCode?.indexOf(paperformStr);

  // extracts the paperform id from the embed code
  let paperformId = "";
  for (let i = formIdIndex + paperformStr.length; i < embedCode.length; i++) {
    if (embedCode[i] === `"`) {
      break;
    }

    paperformId += embedCode[i];
  }

  // extracts the js script source from the embed code
  if (srcUrlIndex !== -1) {
    let srcUrl = "";
    for (let i = srcUrlIndex; i < embedCode.length; i++) {
      if (embedCode[i] === `"`) {
        break;
      }

      srcUrl += embedCode[i];
    }

    // adds the js script to the page
    useScriptSrc(srcUrl);
  } else {
    return <div id="paperform">ERROR: Missing form.</div>;
  }

  return (
    <div
      style={{
        maxWidth: "700px",
      }}
      data-testid="paperform"
      id="paperform"
      data-paperform-id={paperformId}
      data-prefill-inherit="1"
    ></div>
  );
};

export default PaperformEmbed;
