import React from "react";
import CardGrid from "../cardGrid";
import TestimonialWidget from "../testimonialWidget";
import ImageTestimonial from "../testimonialWidgetImage";
import { useStyles } from "./testimonials.styles";

interface ITestimonialWidget {
  image?: IAsset;
  content: IContent;
  authorName: string;
}

interface IPropsBlok extends IBlok {
  heading: string;
  testimonials: ITestimonialWidget[];
}

interface ITestimonials {
  blok: IPropsBlok;
}

const Testimonials = ({ blok }: ITestimonials) => {
  const classes = useStyles();
  return (
    <CardGrid id={blok.anchorTag} heading={blok.heading}>
      {blok.testimonials.map(
        (testimonial: ITestimonialWidget, index: number) => {
          return testimonial.image ? (
            <ImageTestimonial
              style={{
                marginTop: index === 0 ? "50px" : "0",
              }}
              image={testimonial?.image}
              content={testimonial.content}
              authorName={testimonial.authorName}
            />
          ) : (
            <TestimonialWidget
              content={testimonial.content}
              authorName={testimonial.authorName}
            />
          );
        }
      )}
    </CardGrid>
  );
};

export default Testimonials;
