import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      backgroundColor: "#4B999C",
      textAlign: "center",
      padding: "21px 24px",
      position: "sticky",
      top: 0,
      zIndex: 9999,
    },

    text: {
      color: "#fff",
      fontSize: "20px",
      lineHeight: "32px",

      [theme.breakpoints.up("sm")]: {
        fontSize: "24px",
        lineHeight: "38.4px",
      },
    },
  });
});
