import React, { useState, useEffect } from "react";
import Card from "../card";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./countdownTimer.styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface IPropsBlok extends IBlok {
  heading: string;
  countdownDate: string;
}

interface IProps {
  blok: IPropsBlok;
}

interface ITimeWidget {
  time: number;
  unit: "days" | "hours" | "minutes" | "seconds" | "hrs" | "mins" | "secs";
}

const TimeWidget = ({ time, unit }: ITimeWidget) => {
  return (
    <div>
      <Typography variant="h3" component="span">
        {time === 0 ? "00" : time}
      </Typography>
      <Typography
        variant="h4"
        component="p"
        style={{
          marginTop: 8,
          color: "rgba(99, 100, 99, 0.64)",
          textTransform: "uppercase",
          fontSize: 18,
        }}
      >
        {unit}
      </Typography>
    </div>
  );
};

const CountdownTimer = ({ blok }: IProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  // Splits the countdown date string into individual variables for each unit
  const [year, month, date, hours, minutes] =
    blok.countdownDate.split(/\-| |:/);

  // generates a UTC date using the countdown date variables
  const targetDate = Date.UTC(
    Number(year),
    Number(month) - 1,
    Number(date),
    Number(hours),
    Number(minutes)
  );

  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;

  const [daysUntil, setDaysUntil] = useState(getTimeUntil("day"));
  const [hoursUntil, setHoursUntil] = useState(getTimeUntil("hour"));
  const [minutesUntil, setMinutesUntil] = useState(getTimeUntil("minute"));
  const [secondsUntil, setSecondsUntil] = useState(getTimeUntil("second"));

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        daysUntil === 0 &&
        hoursUntil === 0 &&
        minutesUntil === 0 &&
        secondsUntil === 0
      ) {
        clearInterval(timer);
      }
      if (daysUntil > 0) {
        setDaysUntil(getTimeUntil("day"));
      }
      if (hoursUntil > 0) {
        setHoursUntil(getTimeUntil("hour"));
      }
      if (minutesUntil > 0) {
        setMinutesUntil(getTimeUntil("minute"));
      }
      if (secondsUntil > 0) {
        setSecondsUntil(getTimeUntil("second"));
      }
    }, 1000);
  });

  function getTimeUntil(unit: "day" | "hour" | "minute" | "second") {
    const now = new Date();
    let distance = targetDate - now.getTime();

    if (distance <= 0) {
      return 0;
    }

    // depending on the unit of time being calculated utilize different equations
    switch (unit) {
      case "day":
        return Math.floor(distance / _day);
      case "hour":
        return Math.floor((distance % _day) / _hour);
      case "minute":
        return Math.floor((distance % _hour) / _minute);
      case "second":
        return Math.floor((distance % _minute) / _second);
      default:
        return 0;
    }
  }

  return (
    <div className={classes.container} id={blok.anchorTag}>
      <Typography variant="h3" component="h3">
        {blok.heading}
      </Typography>
      <Card className={classes.timer}>
        <TimeWidget time={daysUntil} unit="days" />
        <Typography variant="h3" component="span">
          :
        </Typography>
        <TimeWidget time={hoursUntil} unit={isMobile ? "hrs" : "hours"} />

        <Typography variant="h3" component="span">
          :
        </Typography>
        <TimeWidget time={minutesUntil} unit={isMobile ? "mins" : "minutes"} />
        <Typography variant="h3" component="span">
          :
        </Typography>
        <TimeWidget time={secondsUntil} unit={isMobile ? "secs" : "seconds"} />
      </Card>
    </div>
  );
};

export default CountdownTimer;
