import React from "react";
import Card from "../card";
import Typography from "@material-ui/core/Typography";
import Paragraphs from "../paragraphs";
import { useStyles } from "./instructionWidget.styles";

interface IProps {
  heading: string;
  subheading: string;
  text: IContent;
}

const InstructionWidget = ({ heading, subheading, text }: IProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div>
        <Typography variant="h3" component="h3">
          {heading}
        </Typography>
        <Typography className={classes.subheading} variant="h4" component="h5">
          {subheading}
        </Typography>
      </div>
      <div className={classes.textWrapper}>
        <Paragraphs paragraphs={text} />
      </div>
    </Card>
  );
};

export default InstructionWidget;
