import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    btnWrapper: {
      padding: "50px 0",
      width: "100%",
      maxWidth: "768px",
      [theme.breakpoints.up("sm")]: {
        padding: "64px 0",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "64px 0 0",
      },
    },
  });
});
